.industries {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
}

.industries h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
}

.industries h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.industries p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
    margin-left: 0.5rem;
}

.industry-wrapper{
    display: flex;
    flex-wrap: wrap;
}

.retail {
    background-image: url('./assets/retail_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.health{
    background-image: url('./assets/healthcare_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.manufacture{
    background-image: url('./assets/manufacture_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.construction{
    background-image: url('./assets/construction_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.realestate{
    background-image: url('./assets/realestate_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.financial{
    background-image: url('./assets/finance_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.entertainment{
    background-image: url('./assets/entertainment_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.publicservice{
    background-image: url('./assets/public-service_One5Digital.png');
    width: 280px;
    height: 150px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

ul.row1-items {
    display: flex;
    flex-wrap: wrap;
}
.row1-items li {
    flex: 1 1 20%;
}


@media only screen and (max-width:800px){
    .industries{
        padding: 3rem 1rem;
        height: auto;
    }
    .industries h1{
        font-size: 42px;
    }
    .industries p{
        font-size: 16px;
        padding-right: 0;
		margin-left: 0;
    }
    ul.row1-items{ 
        flex-wrap: wrap;
        justify-content: center;
    }
    ul.row2-items{ 
        flex-wrap: wrap;
        justify-content: center;
    }
    .retail, .realestate{
        margin: 0.5rem;
    }
}

@media only screen and (max-width:600px){
    .industries{
        height: auto;
    }
    .industries h6{
        font-size: 12px;
    }
    .industries h1{
        font-size: 24px;
    }
    
	.row1-items li {
		flex: 0 0 46%;
	}


    ul.row1-items{
        flex: 0 0 50%;
    }
    ul.row2-items{
        flex: 0 0 50%;
    }
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .industries{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .industries{
        height: auto;
    } 
    .row1-items li {
		flex: 0 0 31%;
	}
	.realestate {
		margin: 0.5rem 0.5rem 0.5rem 0.5rem;
	}
	.construction, .entertainment {
		margin: 0.5rem 0.5rem 0.5rem 0;
	}
	.financial, .manufacture {
		margin-right: 0;
	}

}

@media only screen and (max-width : 767px) {
	.industries h6 {
		font-size: 12px;
		margin-bottom: 0;
	}
	.row1-items li {
		flex: 0 0 47%;
		height: 80px;
		width: 47%;
		padding: 0;
	}
	.retail, .realestate,.manufacture , .entertainment{
		margin-left: 0;
	}
	.health, .construction , .financial, .publicservice {
		margin-right: 0;
	}
	
}