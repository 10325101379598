.sbdesc {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
    width: 100%;
}

.sbdesc h1 {
    margin-bottom: 2rem;
	margin-top: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.sbdesc h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.sbdesc p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
    width: 100%;
}

img.sbdescri {
    width: 470px;
}

.sbdesc-cat{
    display: flex;
    margin-top: 4rem;
}

.sbdesc-left{
    float: left;
    width: 70%;
    display: flex;
}

.sbdesc-right{
    float: right;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: top;
}

img.sbdesc2ri {
    width: 310px;
    height: 400px;
    margin-left: 7rem;
}

.sbdesc-cat2{
    display: flex;
    margin-top: 5rem;
}

.sbdesc2-left{
    float: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

.sbdesc2-right{
    float: right;
    width: 40%;
    display: flex;
}

@media only screen and (max-width:800px){
    .sbdesc{
        padding: 3rem 1rem;
        height: auto;
    }
    .sbdesc h1{
        font-size: 42px;
		margin-top: 0;	
    }
    .sbdesc h6{
        font-size: 16px;
    }
    .sbdesc p{
        font-size: 16px;
        width: 100%;
    }
    .sbdesc-left{
        width: 32%;
    }
    .sbdesc-right{
        width: 68%;
    }
    .sbdesc2-right{
        padding-top: 2rem;
    }
    img.sbdescri {
		width: 400px;
		height: auto;
		position: absolute;
		left: -135px;
	}
    img.sbdesc2ri{
        width: 280px;
        height: 330px;
        margin-top: 0rem;
        margin-left: 0;
    }
	.sbdesc-cat {
		display: flex;
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media only screen and (max-width:600px){
    .sbdesc{
        height: auto;
    }
    .sbdesc h1{
        font-size: 24px;
    }
    .sbdesc p{
        font-size: 12px;
        width: 100%;
		padding: 0;
    }
    .sbdesc-cat{
        flex-direction: column-reverse;
        margin-top: 0;
    }
    .sbdesc-left{
        width: 100%;
        justify-content: center;
    }
    .sbdesc-right{
        width: 100%;
    }
    .sbdesc-cat2{
        flex-direction: column;
    }
    .sbdesc2-left{
        width: 100%;
    }
    .sbdesc2-right{
        width: 100%;
        justify-content: center;
    }
	img.sbdesc2ri{
        margin-top: 2rem;
    }
	img.sbdescri {
		width: 100%;
		height: auto;
		position: relative;
		left: 0;
	}
}    