.back-to-top .top {
    position: fixed;
    cursor: pointer;
    bottom: 15px;
    right: 15px;
    font-size: 25px;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  