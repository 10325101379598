.about-page {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/about-page_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    display: flex;
}

.about-page h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}
h1 span, h4 span {
    margin: 0;
}
.about-page h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.about-page p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
}

.about-left{
    float: left;
    width: 70%;
    margin-top: 1rem;
}

.about-right{
    float: right;
    width: 30%;
    margin-top: -4rem;
}

@media only screen and (max-width:800px){
    .about-page{
        padding: 3rem 1rem;
        height: auto;
    }
    .about-page h1{
        font-size: 42px;
    }
    .about-page p{
        font-size: 16px;
    }
    .ab-vector{
        width: 160px;
    }
    .about-left{
        width: 95%;
    }
    .about-right{
        margin-top: -2rem;
    }
}

@media only screen and (max-width:600px){
    .about-page{
        height: auto;
    }
    .about-left{
        width: 100%;
    }
    .ab-vector{
        display: none;
    }
    .about-right {
		display: none;
	}
	.about-page h1 {
    font-size: 26px;
	margin-bottom: 10px;
}
}