.VDesign-about {
    padding: 5rem;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.VDesign-about h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #121212;
    margin-top: 3rem;
    text-transform: uppercase;
}

.VDesign-about h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #121212;
}

.VDesign-about p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000000;
}

.VDesign-about-btn {
    margin-top: 2rem;
}

li.lda-items { 
    color: #121212;
    font-size: 1rem;
}

.dev-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

.vdservice-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.vdservice-1 p {
    font-size: 0.9rem;
}

.vdservice-2 p {
    font-size: 0.9rem;
}

.vdservice-3 p {
    font-size: 0.9rem;
}

.vdservice-4 p {
    font-size: 0.9rem;
}

ul.vdservice-1 {
    margin-left: 1rem;
    width: 30%;
    height: auto;
}

ul.vdservice-2, ul.vdservice-3, ul.vdservice-4 {
    width: 30%;
    height: auto;
}

p.vd-desc {
    color: #000;
    width: 90%;
    text-align: justify;
}

h1.ecno {
    color: #F04E31;
}

@media only screen and (max-width:800px){
    .VDesign-about{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .VDesign-about h1{
        font-size: 42px;
    }
    .VDesign-about p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
	li.lda-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.lda-items:last-child {
		margin: 0;
	}
    p.vd-desc{
        width: 80%;
    }
    .vdservice-wrapper{
        flex: 0 0 50%;
    }
    .vdservice-1, .vdservice-2, .vdservice-3, .vdservice-4{
        flex: 0 0 50%;
    }
    ul.vdservice-1{
        margin-left: 0;
    }
    ul.vdservice-1, ul.vdservice-2, ul.vdservice-3, ul.vdservice-4 {
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width:600px){
    .VDesign-about{
        padding: 3rem 1rem;
        height: auto;
    }
    .VDesign-about h1{
        font-size: 30px;
    }
    .VDesign-about p{
        font-size: 12px;
        width: 100%;
    }
    li.dev-items {
		padding: 0;
	}
    .vdservice-wrapper{
        flex-direction: column;
    }
    ul.vdservice-1, ul.vdservice-2, ul.vdservice-3, ul.vdservice-4 {
        width: 100%;
    }
    ul.vdservice-1{
        margin-left: 0;
    }
    .vdservice h1 {
		font-size: 24px;
	}
	p.vd-desc {
		width: 100%;
		padding: 0;
		text-align: left;
	}
	ul.vdservice-1, ul.vdservice-2, ul.vdservice-3, ul.vdservice-4 {
        height: auto;
    }
	ul.vdservice-4 {
        margin-bottom: 0;
    }
}