:root {
    --primary: #F04E31;
}

.btn {
    padding: 8px 20px;
    border-radius: 30px; 
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #F04E31;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}


.btn--white {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--white:hover {
    background-color: #fff;
    color: #F04E31;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 16px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 16px;
}

.btn--medium:hover, .btn--large:hover {
    background: transparent;
    color: #F04E31;
    transition: all 0.3s ease-out;
}


.btn--outline:hover {
    background-color: #F04E31;
    color: #fff;
}

.btn--orange {
    padding: 12px 100px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #F04E31;
    color: #F04E31;
}

.btn--orange:hover {
    background-color: #F04E31;
    color: #fff;
}

.btn--owhite {
    padding: 12px 100px;
    font-size: 16px;
    background: transparent;
    border: 1px solid white;
    color: white;
}

.btn--owhite:hover {
    background-color: #fff;
    color: #F04E31;
}

.btn--orangel {
    padding: 12px 60px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #F04E31;
    color: #F04E31;
}

.btn--orangel:hover {
    background-color: #F04E31;
    color: #fff;
}

.btn--owhitel {
    padding: 12px 60px;
    font-size: 16px;
    background: transparent;
    border: 1px solid white;
    color: white;
}

.btn--owhitel:hover {
    background-color: #fff;
    color: #F04E31;
}

@media only screen and (max-width:800px){
    .btn--orange{
        padding: 8px 20px;
    }
    .btn--owhite{
        padding: 8px 20px;
    }
}

@media only screen and (max-width:500px){
    .btn--orange{
        padding: 12px 100px;
    }
    .btn--owhite{
        padding: 12px 100px;
    }
}