.MCDesign-desc {
    padding: 5rem;
    height: 40vh;
    background-image: url('./assets/bg-white-mainpage_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover; 
}

.MCDesign-desc h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #1c1c1c;
    margin-top: 3rem;
    text-transform: uppercase;
}

.MCDesign-desc h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #1c1c1c;
}

.MCDesign-desc p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #1c1c1c;
}

.design-desc {
    text-align: center;
}


@media only screen and (max-width:800px){
    .MCDesign-desc{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .MCDesign-desc h1{
        font-size: 42px;
    }
    .MCDesign-desc p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
	li.lda-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.lda-items:last-child {
		margin: 0;
	}
}

@media only screen and (max-width:600px){
    .MCDesign-desc{
        padding: 3rem 1rem;
        height: auto;
    }
    .MCDesign-desc h1{
        font-size: 30px;
    }
    .MCDesign-desc p{
        font-size: 12px;
        width: 100%;
    }
    li.dev-items {
		padding: 0;
	}
}