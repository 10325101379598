.ecabout {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/ecom-bg_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.ecabout h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    margin-top: 3rem;
}

.ecabout h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.ecabout p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 70%;
}

.ecabout-btn {
    margin-top: 2rem;
}

@media only screen and (max-width:800px){
    .ecabout{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .ecabout h1{
        font-size: 42px;
    }
    .ecabout p{
        font-size: 16px;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .ecabout{
        height: auto;
    }
    .ecabout h1{
        font-size: 24px;
        margin-bottom: 15px;
    }
    .ecabout p{
        font-size: 12px;
        width: 100%;
        padding: 0;
    }
}