.SMDesign-about {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/about-white_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.SMDesign-about h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #121212;
    margin-top: 3rem;
    text-transform: uppercase;
}

.SMDesign-about h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #121212;
}

.SMDesign-about p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000000;
}

.SMDesign-about-btn {
    margin-top: 2rem;
}

li.lda-items { 
    color: #121212;
    font-size: 1rem;
}

.dev-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

.smdservice-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
}

.smdservice-1 p {
    font-size: 0.9rem;
}

.smdservice-2 p {
    font-size: 0.9rem;
}

.smdservice-3 p {
    font-size: 0.9rem;
}

.smdservice-4 p {
    font-size: 0.9rem;
}

ul.smdservice-1 {
    margin-left: 1rem;
    width: 24%;
}

ul.smdservice-2, ul.smdservice-3, ul.smdservice-4 {
    height: auto;
    width: 24%;
}

p.smd-desc {
    color: #000;
    width: 90%;
    text-align: justify;
}

h1.ecno {
    color: #F04E31;
}

.smdesc{
    margin-top: 3rem;
}

@media only screen and (max-width:800px){
    .SMDesign-about{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .SMDesign-about h1{
        font-size: 42px;
    }
    .SMDesign-about p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
	li.lda-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.lda-items:last-child {
		margin: 0;
	}
    p.smd-desc{
        width: 80%;
    }
    .smdservice-wrapper{
        flex: 0 0 50%;
    }
    .smdservice-1, .smdservice-2, .smdservice-3, .smdservice-4{
        flex: 0 0 50%;
    }
    ul.smdservice-1{
        margin-left: 0;
    }
    ul.smdservice-1, ul.smdservice-2, ul.smdservice-3, ul.smdservice-4 {
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width:600px){
    .SMDesign-about{
        padding: 3rem 1rem;
        height: auto;
    }
    .SMDesign-about h1{
        font-size: 30px;
    }
    .SMDesign-about p{
        font-size: 12px;
        width: 100%;
    }
    li.dev-items {
		padding: 0;
	}
    .smdservice-wrapper{
        flex-direction: column;
    }
    ul.smdservice-1, ul.smdservice-2, ul.smdservice-3, ul.smdservice-4 {
        width: 100%;
    }
    ul.smdservice-1{
        margin-left: 0;
    }
    .smdservice h1 {
		font-size: 24px;
	}
	p.smd-desc {
		width: 100%;
		padding: 0;
		text-align: left;
	}
	ul.smdservice-1, ul.smdservice-2, ul.smdservice-3, ul.smdservice-4 {
        height: auto;
    }
	ul.smdservice-4 {
        margin-bottom: 0;
    }
}