.our-projects {
    padding: 5rem 5rem;
    height: auto;
    background: #1c1c1c;
}

.our-projects h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}
.our-projects h1 span{
margin:0;
}

.our-projects h3 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.our-projects p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #9a9a9a;
}

.left-desc{
    float: left;
    width: 60%;
}

.right-desc{
    float: right;
    width: 40%;
    align-items: baseline;
    padding-top: 2.4rem;
}

.right-desc p {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 0px;
}

p.projects-desc{
    width: 60%;
}

.project-1 p {
    font-size: 0.9rem;
}

.project-2 p {
    font-size: 0.9rem;
}

.project-3 p {
    font-size: 0.9rem;
}

.project-4 p {
    font-size: 0.9rem;
}

.project-5 p {
    font-size: 0.9rem;
}

.project-6 p {
    font-size: 0.9rem;
}
.project-wrapper > div {
	display: block;
	overflow: hidden;
	width: 100%;
}

.p1-desc, .p3-desc, .p5-desc {
    float: left;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

.p2-desc, .p4-desc, .p6-desc {
    float: right;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 4rem;
}

.p1-img, .p3-img, .p5-img {
    float: right;
    width: 50%;
    margin-top: 2rem;
    opacity: 1;
}

.p2-img, .p4-img, .p6-img {
    float: left;
    width: 50%;
    margin-top: 2rem;
}

.videos {
    position: relative;
    width: 490px;
    height: 334px;
    cursor: pointer;
    visibility: hidden;
    margin-top: -20rem;
    top: -1.5rem;
}
.p1-img:hover .pimg{
    visibility: hidden;
}
.p1-img:hover .videos{ 
    visibility: visible;
}
.p2-img:hover .pimg{
    visibility: hidden;
}
.p2-img:hover .videos{
    visibility: visible;
}
.p3-img:hover .pimg{
    visibility: hidden;
}
.p3-img:hover .videos{
    visibility: visible;
}
.p4-img:hover .pimg{
    visibility: hidden;
}
.p4-img:hover .videos{
    visibility: visible;
}
.p5-img:hover .pimg{
    visibility: hidden;
}
.p5-img:hover .videos{
    visibility: visible;
}
.p6-img:hover .pimg{
    visibility: hidden;
}
.p6-img:hover .videos{
    visibility: visible;
}

@media only screen and (max-width:767px){
    .our-projects{
        padding: 3rem 1rem;
        height: 355vh;
    }
    .our-projects h1{
        font-size: 42px;
        margin-bottom: 1rem;
    }
    .our-projects p{
        font-size: 12px;
    }
    .left-desc{
        width: 100%;
    }
    .right-desc{
        width: 100%;
        padding-top: 1rem;
    }
    img.pimg{
        width: 380px;
    }

    .videos{
        width: 380px;
        height: auto;
    }

    .p1-desc, .p3-desc{
        width: 50%;
        height: 270px;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    .p5-desc{
        height: 300px;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    .p2-desc, .p4-desc, .p6-desc{
        height: 270px;
        width: 300px;
        margin-top: 2rem;
        padding: 0;
        margin-left: 2rem;
    }
    .project-wrapper{
        display: flex;
        flex: 50%;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .project-1{
        margin-top: 5rem;
        width: auto;
        height: auto;
        flex: 0 0 50%;
        display: flex;
    }
    .project-2, .project-3, .project-4, .project-5, .project-6{
        width: auto;
        height: auto;
        flex: 0 0 50%;
        display: flex;
    }
	.projects-desc {
	margin-bottom: 0px !important;
}
.project-wrapper > div {
	display: flex;
margin-bottom: 60px;
}
.project-wrapper > div .p1-desc , .project-wrapper > div .p2-desc, .project-wrapper > div .p3-desc, .project-wrapper > div .p4-desc, .project-wrapper > div .p5-desc, .project-wrapper > div .p6-desc {
    order: 2;
}
.p2-img, .p4-img, .p6-img {
	width: 100%;
	margin-top: 0rem;
}
.project-wrapper p {
	margin-bottom: 15px;
}
.p1-desc, .p3-desc, .p2-desc, .p4-desc, .p5-desc, .p6-desc {
	height: auto !important;
}
.projects-desc {
	margin-bottom: 40px !important;
}
.p1-desc,.p2-desc, .p3-desc,.p4-desc,.p5-desc,.p6-desc {
	width: 100% !important;
	margin-top: 0 !important;
	margin-left: 0rem !important;
}

    .our-projects{
        height: auto;
    }
    .our-projects h1{
        font-size: 24px;
		margin-bottom: 10px;
    }
    .project-wrapper{
        flex-direction: column;
    }
    .project-1, .project-2, .project-3, .project-4, .project-5, .project-6{
        flex-direction: column;
    }
    .p1-desc, .p2-desc, .p3-desc, .p4-desc, .p5-desc, .p6-desc{
        order: 2;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-left: 0;
        margin-top: 0;
    }
    .p1-img, .p2-img, .p3-img, .p4-img, .p5-img, .p6-img{
        order: 1;
    }
	.our-projects p {
	margin-bottom: 10px;
}
.right-desc p {
	font-size: 12rpx;
	width: 100%;
}
.right-desc {
	width: 100%;
	padding-top: 0;
}
.project-wrapper {
	display: block;
	flex: 100% 1;
	flex-wrap: wrap;
	flex-direction: column;
}

img.pimg {
    width: 100%;
}
.videos{
    width: 100%;
    height: auto;
    margin-top: -29rem;
}
.project-1 {
    display: block;
}
.p1-desc, .p3-desc {
    width: 50%;
    height: auto;
    margin-top: 2rem;
    margin-left: 1rem;
	display: block;
}
.p1-img, .p3-img, .p5-img {
	float: left;
	width: 100%;
	margin-top: 0rem;
}
}

@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .our-projects{
        height: auto;
    }
	.pimg {
	width: 100%;
}
.videos{
    width: 100%;
    height: auto;
}
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 900px){
      .our-projects{
        height: auto;
    } 
	.our-projects h1 {
	margin-bottom: 0rem;
}
p.projects-desc {
	width: 100%;
}


.p2-desc, .p4-desc, .p6-desc {
    height: 270px !important;
    width: 50% !important;
    margin-left: 0 !important;
}
.p1-desc, .p3-desc, .p5-desc {
    height: 270px !important;
    width: 47% !important;
    margin-top: 3rem !important;
    margin-left: 1rem !important;
}
}
@media only screen and (min-device-width : 901px) and (max-device-width : 1100px){
      .our-projects{
        height: auto;
    } 
	.our-projects h1 {
	margin-bottom: 0rem;
}
p.projects-desc {
	width: 100%;
}

.p1-desc, .p3-desc {
    width: 50%;
	height: 340px;
	margin-left: 0 !important;
}
.p2-desc, .p4-desc, .p6-desc {
    height: 340px;
    width: 50%;
    margin-left: 0;
}
}
@media only screen and (max-device-width : 800px) {
.our-projects {
	padding: 3rem 1rem;
}
}