.sddetail {
    padding: 5rem 5rem;
    height: auto;
    background: #121212;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.sddetail-container {
    height: auto;
}

.sddetailcol-left{
    float: left;
    color: #F04E31;
    width: 50%;
    margin-top: -0.5rem;
}

.sddetailcol-left h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.sddetailcol-full h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.sddetailcol-left p, .sddetailcol-full p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
    width: 80%;
}

.sddetailcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.sddetailcol-left btn{
    margin-top: 1.2rem;
}

.sddetailcol-right {
    float: right;
    width: 50%;
}

img.sbimg{
    width: 100%;
    margin-top: -50px;
    display: block;
}

img.sbimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}

@media only screen and (max-width:800px){
    .sddetail{
        padding: 3rem 1rem;
        height: auto;
        overflow: hidden;
    }
    .sddetail h1{
        font-size: 42px;
    }
    .sddetail h6{
        font-size: 16px;
    }
    .sddetail p{
        font-size: 16px;
        width: 100%;
    }
    img.sbimg{
        display: none;
    }
    img.sbimg2{
        display: block;
        margin-top: -50px;
    }    
    .sddetailcol-left{
        width: 50%;
    }
    .sddetailcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .sddetail{
        height: auto;
    }
    /* .sddetail h1{
        font-size: 24px;
        text-align: center;
    } */
    .sddetail p{
        font-size: 12px;
        width: 100%;
        padding: 0;
    }
    img.sbimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.sbimg2{
        display: none;
    }
    .sddetail-container {
        display: flex;
        flex-wrap: wrap;
    }
    .sddetailcol-full {
        width: 100%;
        order: 1;
    }
    .sddetailcol-left{
        width: 100%;
        order: 3;
    }
    .sddetailcol-right{
        width: 100%;
        order: 2;
        margin-bottom: 2rem;
    }
    
    
    
}
