.featuredpro {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
}

.featuredpro h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.featuredpro h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.featuredpro p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

.featuredpro-wrapper{
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
}

img.featuredproimg{
    width: 100%;
}

ul.featuredprorow-items{
    display: flex;
    flex-wrap: wrap;
}
.featuredprorow-items li {
	width: 25%;
	display: block;
}

@media only screen and (max-width:800px){
    .featuredproject{
        padding: 3rem 1rem;
        height: auto;
    }
    .featuredproject h1{
        font-size: 34px;
    }
    .featuredpro-wrapper{
        width: auto;
        height: auto;
        flex: 0 0 50%;
    }
    ul.featuredprorow-items{
        justify-content: center;
    }
    .featuredprorow-items li {
		margin-bottom: 10px;
		padding: 0 10px;
	}
	.featuredprorow-items li {
		width: 50%;
		display: block;
	}
}

@media only screen and (max-width:600px){
    .featuredproject{
        height: auto;
    }
    img.featuredproimg{
        width: 100%;
    }
    .featuredproject h1{
        font-size: 24px;
    }
	.featuredprorow-items li {
		padding: 0;
	}
	.featuredprorow-items li {
		width: 100%;
	}
    
}