.RNDesign-about {
    padding: 5rem;
    height: 80vh;
    background: #1c1c1c;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover; 
}

.RNDesign-about h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #ffffff;
    margin-top: 3rem;
    text-transform: uppercase;
}

.RNDesign-about h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #ffffff;
}

.RNDesign-about p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #ffffff;
}

.RNDesign-about-btn {
    margin-top: 2rem;
}

li.lda-items { 
    color: #ffffff;
    font-size: 1rem;
}

.dev-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

@media only screen and (max-width:800px){
    .RNDesign-about{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .RNDesign-about h1{
        font-size: 42px;
    }
    .RNDesign-about p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
	li.lda-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.lda-items:last-child {
		margin: 0;
	}
}

@media only screen and (max-width:600px){
    .RNDesign-about{
        padding: 3rem 1rem;
        height: auto;
    }
    .RNDesign-about h1{
        font-size: 30px;
    }
    .RNDesign-about p{
        font-size: 12px;
        width: 100%;
    }
    li.dev-items {
		padding: 0;
	}
}