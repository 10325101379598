.arabout {
    padding: 5rem 0 5rem 5rem;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.arabout-container {
    height: auto;
}

.araboutcol-left{
    float: left;
    color: #000;
    width: 50%;
}

.araboutcol-left h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.ebaboutcol-full h1 {
    araboutcol-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.araboutcol-left p , .araboutcol-full p{
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
    width: 80%;
}

.araboutcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.araboutcol-left btn{
    margin-top: 1.2rem;
}

.araboutcol-right {
    float: right;
    width: 50%;
}


img.arimg{
    width: 100%;
	margin-top: -50px;
    display: block;
}

img.arimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
	margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}

@media only screen and (max-width:800px){
    .arabout{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .arabout h1{
        font-size: 42px;
    }
    .arabout h6{
        font-size: 16px;
    }
    .arabout p{
        font-size: 16px;
        width: 100%;
    }
    img.arimg{
        display: none;
    }
    img.arimg2{
        display: block;
        margin-top: -20px;
    }    
    .araboutcol-left{
        width: 50%;
    }
    .araboutcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .arabout{
        height: auto;
    }
    .arabout h1{
        font-size: 24px;
		text-align: center;
    }
    .arabout p{
        font-size: 12px;
        width: 100%;
		padding: 0;
    }
    img.arimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.arimg2{
        display: none;
    }
	
	.arabout-container {
		display: flex;
		flex-wrap: wrap;
	}
	.araboutcol-full {
		width: 100%;
		order: 1;
	}
    .araboutcol-left{
        width: 100%;
		order: 3;
    }
    .araboutcol-right{
        width: 100%;
		order: 2;
		margin-bottom: 2rem;
    }
}