.testimonial {
    padding: 3rem 5rem;
    height: auto;
}

.testimonial h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #f04e31;
    text-align: center;
}
.testimonial h3{
    font-size: 1.5rem;
}
.testimonial h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.testimonial p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-style: normal;
    padding-right: 15px;
    color: #0d0c22;
}
.test-content{
    padding: 0 1rem;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 85rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.test-content>*{
    flex: 1;
    margin: 1rem;
}
.test-content .tlist{
    column-count: 2;
    column-fill: balance;
    column-gap: 2rem;
    transform: translateX(0);
    margin: 1rem 0;
}
.tlist>*{
    overflow: visible;
    break-inside: avoid;
    display: inline-block;
}
.test-list .blockquote.bemp{
    transform: translateZ(0);
}
.test-list .blockquote{
    margin: 0 0 1rem;
}
.b-bubble{
    padding: 0;
    display: inline-block;
}
.blockquote{
    line-height: 1.4;
    letter-spacing: -0.025rem;
    color: #0d0c22;
    quotes: " “ " " ” ";
}
.blockquote p:before {
    content: '“';
    position: absolute;
    font-size: 3em;
    color: #f04e31;
    font-weight: 600;
    left: -0.5em;
    top: -0.25em;
    line-height: 1;
}
.b-bubble .bcontent:after {
    content: '';
    background-color: inherit;
    position: absolute;
    width: 4rem;
    height: 1rem;
    bottom: -1rem;
    left: 2rem;
    mask: url('./assets/blockquote-beak_One5Digital.svg') center top/ contain no-repeat;
}
.b-bubble .bcontent{
    border-radius: 0.5rem;
    padding: 2rem;
    margin: 0 0 1rem;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    position: relative;
    background: #f3f4f4;
}
.blockquote p{
    position: relative;
    line-height: 1.4;
}
.spanc{
    color: #f04e31;
    text-decoration: none;
    transition: color 200ms ease;
}
.b-bubble .b-cite{
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
}
.b-cite{
    font-style: normal;
    letter-spacing: 0;
    margin-top: 20px;
}
.b-bubble .b-cite>*{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.b-bubble.bemp .bcontent{
    background: #fff;
    box-shadow: 0 1.5rem 4rem rgb(0 0 0 / 10%);
    z-index: 1;
}
p.test-p{
    font-size: 1.5rem;
}


@media screen and (max-width: 960px) {
    .test-col{
        flex: 100%;
        max-width: 80%;
    }
	.testimonial {
		padding: 3rem 1rem;
	}
	.test-content {
		padding: 0;
	}
	.test-list.tlist li {
		padding: 0;
	}
}
@media screen and (max-width: 600px) {
    .test-col{
        flex: 100%;
        max-width: 100%;
    }
	.testimonial h1 {
		font-size: 24px;
	}
	.test-content .tlist {
		column-count: 1;
	}
	.test-content > * {
		flex: 1 1;
		margin: 0;
	}
}