.approach {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/about-black_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.approach h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.approach h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.approach p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
}

.app-left{
    float: left;
    width: 45%;
}

.app-right{
    float: right;
    width: 40%;
    align-content: flex-end;
}

li.app-items {
    color: #fff;
    font-size: 1rem;
}

.app-list {
    overflow-y: scroll;
    white-space: normal;
    height: 350px;
}
.app-items p br {
	display: none;
}

.scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
  }
  
.scrollbar-warning::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}
  
.scrollbar-warning::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    background-color: #F5F5F5; 
}
  
.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F04E31; 
}

@media only screen and (max-width:800px){
    .approach{
        padding: 3rem 1rem;
        height: auto;
    }
    .approach h1{
        font-size: 34px;
    }
    .approach p{
        font-size: 14px;
    }
    .app-left{
        width: 40%;
    }
    .app-right{
        width: 60%;
    }
    .app-list {
		overflow-y: hidden;
		height: auto;
	}
	li.app-items {
		margin-bottom: 20px;
	}
	li.app-items h4 {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width:600px){
    .approach{
        height: auto;
    }
    .approach h1{
        font-size: 24px;
        width: 100%;
    }
    .approach p{
        font-size: 12px;
    }
    .app-left{
        width: 100%;
    }
    .app-right{
        width: 100%;
    }
    li.app-items {
		padding: 0;
	}
	.app-items p {
		padding: 0;
	}
	.app-list {
		white-space: normal;
		height: auto;
	}
	.approach {
		 background-image: none;
		 background-color: #1C1C1C;
	}
    
}