.header {
    background: transparent;
    color: #000;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: sticky;
    top: 0;
    height: 100px;
	z-index: 22;
}
.header.active {
    background: #1c1c1c;
}

.nav-items {
    display: flex;
    list-style: none;
    font-size: 2.8rem;
    text-decoration: none;
    color: #000;
    margin-bottom: 0rem;
	flex-direction: column;
}

li.nav-items{
    margin-bottom: 0;
    margin-left: 4rem;
}

li {
    padding-left: 1rem;
    padding-right: 1rem;
}

a.nav-items:hover {
    color: #F04E31;
    cursor: pointer;
}

.nav-item-active {
    color: #F04E31;
}

.logo{
    margin-left: 4rem;
    margin-top: 1rem;
}

/* .logowhite{
    visibility: visible;
}
.logored{
    visibility: hidden;
    margin-left: -5rem;
}
.logo:hover .logowhite{
    visibility: hidden;
}
.logo:hover .logored{
    visibility: visible;
} */

.navspan{
    transform-origin: 0 0;
    transform: rotate(270deg);
    margin-top: 6rem;
    margin-left: 2rem;
}

.subnav-items{
    display: none;
}

.subnav:hover .subnav-items {
    position: relative;
    display: block;
    left: 300px;
    text-align: left;
    top: 50px;
}

a.sublink{
    text-decoration: none;
    color: #000;
    font-size: 1.2rem;
}

a.sublink:hover{
    color: #F04E31;
}

p.navspan{
    color: #000;
}



@media only screen and (max-device-width : 1024px){
.logo {
	margin-left: 0;
	margin-top: 6px;
}
}
@media only screen and (max-width:800px){
    .logo{
        margin-left: 0;
    }
    label.sc-bdnxRM.bAGNnK.navi-toggle{
        right: -15px;
    }
}

@media only screen and (max-width:600px){
    .nav-items{
        font-size: 36px;
    }
	.ser-wrapper li {
	font-size: 12px;
}
}
