.aupro {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
    width: 100%;
}

.aupro h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
}

.aupro h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.aupro p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
    width: 60%;
}

.aupro-slider{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

img.slide1, img.slide2, img.slide3{
    max-width: 600px;
}

.page-arrows, .pro {
    text-align: center;
}

/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 600px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    height: 350px;
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #717171;
  }
  

@media only screen and (max-width:800px){
    .aupro{
        padding: 3rem 1rem;
        height: auto;
    }
    .aupro h1{
        font-size: 42px;
    }
    .aupro h6{
        font-size: 16px;
    }
    .aupro p{
        font-size: 16px;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .aupro{
        height: auto;
    }
    .aupro h1{
        font-size: 24px;
		margin-bottom: 10px;
    }
    .aupro p{
        font-size: 12px;
        width: 100%;
    }
    .slideshowSlider img {
        width: 100%;
    }
}