.navbar{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-bars{
    transform: scaleX(-1);
    cursor: pointer;
}
.bar{
    width: 2.5rem;
    height: 2px;
    background-color: #fff;
    right: 0;
}
.bar {
    right: 0;
    width: 2rem;
    height: 2px;
    cursor: pointer;
    background-color: #fff;
    position: absolute;
}
.bar:before{
    position: absolute;
    content: ' ';
    height: 2px;
    width: 2.5rem;
    background-color: #fff;
    margin-top: -0.8rem;
}
.bar:after {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 2.5rem;
    background-color: #fff;
    margin-top: 0.8rem;
}
.navbtn{
    margin-right: 4rem;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 40px;
	padding-left: 8px;
	cursor: pointer;
}
.navbar-toggle{
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 4rem;
}
.menu-bars :hover{
    color: #f04e31;
}
span{
    margin-left: 1rem;
}

.close {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .closemenu {
	height: auto;
	cursor: pointer;
	position: absolute;
	right: 3rem;
	top: 32px;
	z-index: 99999999;
}
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #1c1c1c;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  .close:hover::before{
      background-color: #f04e31;
  }
  .close:hover::after{
    background-color: #f04e31;
}
.nav-menu{ 
    background: #fff;
    color: #000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    padding-left: 2rem;
}
.nav-menu.active{
    right: 0;
    transition: 350ms;
}
.nav-text{
    display: block;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 3.5rem;
}
.nav-text a{
    text-decoration: none;
    color: #1c1c1c;
    font-size: 2rem;
    display: flex;
    align-items: center;
	font-weight:bold;
}
a.pro-list-items {
	font-weight: normal;
	position: relative;
}
.nav-text a:hover{
    color: #f04e31;
}
.navbar-items{
    padding: 0;
	transition: 850ms;
}
.nav-menu.active .navbar-items{
	padding: 25rem 0rem 20rem 0rem;
	transition: 350ms;
}
.container-Links {
	width: 100%;
}
.pro-list{
    position: absolute;
    padding-left: 0;
    display: flex;
	flex-wrap: wrap;
    list-style: none;
    flex-direction: unset;
    height: auto;
    opacity: 1;
    transition: all 0.5s ease;
    transform: rotateY(10px);
    left: 32%;
    display: none;
    width: auto;
    padding-top: 0rem;
	top: 5px;
	z-index: 999999;
}
.pro-list-items{
    text-decoration: none;
    font-size: 1rem !important;
}
.pro-list-items :hover{
    color: #f04e31;
}
.projects{
    position: relative;
}
.projects:hover .pro-list{
    display: flex;
    z-index: 99;
}
.pro-items{
    width: 17rem;
}

.nav-menu .follow-us {
	display: block;
	position: absolute;
	bottom: 8%;
	right: 10%;
	width: auto;
	margin:0;
}

.nav-menu a.social {
	font-size: 14px;
}

li.nav-text .pro-items {
	margin-bottom: 20px;
}
.menutext {
	position: absolute;
	top: 0;
	left: 0;
	text-transform: uppercase;
	font-size: 12px;
}
.pro-list-items {
	display: inline-block !important;
	width: auto;
}
.newtop {
	margin-left: 0;
	font-size: 10px;
	text-transform: capitalize;
	border: 1px solid #f04e31;
	border-radius: 5px;
	background-color: rgba(240, 78, 49,0.2);
	color: #f04e31;
	padding: 0px 4px;
	position: absolute;
	top: -10px;
	right: -35px;
}


@media only screen and (max-device-width : 1024px){
.nav-items {
	margin-bottom: 0;
	margin-right: 0px;
}
.navbtn{
	margin-right: 0px;
}
}

@media only screen and (max-width:800px){
    .nav-menu.active .navbar-items{
        padding: 25rem 0rem 20rem 0;
    }
    .nav-text a{
        font-size: 1.5rem;
    }
}


@media only screen and (min-device-width : 1150px) and (max-device-width : 1350px){
.nav-menu.active .navbar-items{
        padding: 25rem 0rem 20rem 0;
    }
	.nav-text a{
        font-size: 1.5rem;
    }
	.nav-text {
		height: 4rem;
	}
	.pro-list {
		left: 25%;
	}
	.pro-items {
		width: 16rem;
	}
}
@media only screen and (min-device-width : 901px) and (max-device-width : 1149px){
.nav-menu.active .navbar-items{
        padding: 25rem 0rem 20rem 0;
    }
	.nav-text a{
        font-size: 1.5rem;
    }
	.nav-text {
		height: 3rem;
	}
	.pro-list {
		left: 35%;
		height: auto;
	}
	.pro-items {
		width: 16rem;
	}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 900px){
.nav-menu.active .navbar-items{
        padding: 22rem 0rem 20rem 0;
    }
	.nav-text a{
        font-size: 1.3rem;
    }
	.nav-text {
		height: 4rem;
	}
	.pro-list {
		left: 33%;
		height: auto;
	}
	.pro-items {
		width: 14rem;
	}
	.pro-list-items {
		font-size: 14px !important;
	}
	.pro-list {
		padding-left: 0;
		padding-top: 0;
	}
	li.nav-text .pro-items {
		margin-bottom: 10px;
	}
	.nav-menu .follow-us {
		bottom: 2%;
		width: auto;
	}
}

@media only screen and (max-width : 767px){
	.nav-text a{
        font-size: 1.5rem;
    }
	.pro-list {
		display: none !important;
	}
}

@media only screen and (max-width:600px){
    .nav-menu.active .navbar-items{
        padding: 25rem 0rem 20rem 0;
    }
	.nav-text a{
        font-size: 1.2rem;
    }
	.nav-menu .follow-us {
		bottom: 2%;
	}
	.nav-text {
		height: 3rem;
	}
	div h1 {
		margin-bottom: 10px !important;
	}
	
}