.solutions {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
}

.solutions h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    width: 80%;
}

.solutions h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.solutions p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
}

.solution-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
}

.solution-1 p {
    font-size: 0.9rem;
}

.solution-2 p {
    font-size: 0.9rem;
}

.solution-3 p {
    font-size: 0.9rem;
}

.solution-4 p {
    font-size: 0.9rem;
}
.sol-list {
    padding-left: 15px;
}
li.sol-items {
    color: #000;
    list-style: disc;
    font-size: 1rem;
    padding: 0 15px 0 0px;
}

::marker  {
    color: #F04E31;
}

.solution-1, .solution-2, .solution-3, .solution-4{
    width: 25%;
    height: auto;
}

@media only screen and (max-width:800px){
    .solutions{
        padding: 3rem 1rem;
        height: auto;
    }
    .solutions h1{
        font-size: 40px;
        width: 100%;
    }
    .solutions p{
        font-size: 16px;
    }
    .solution-1, .solution-2{
    	margin-bottom: 50px;
    }
    .solution-1, .solution-2, .solution-3, .solution-4{
        width: auto;
        height: auto;
        flex: 0 0 50%;
    }
}

@media only screen and (max-width:600px){
    .solutions{
        height: auto;
    }
    .solutions h1{
        font-size: 24px;
    }
    .solutions p{
        font-size: 12px;
    }
    .solution-wrapper{
        flex-direction: column;
    }
    .solution-1, .solution-2{
    	margin-bottom: 0px;
    }
    .solution-2, .solution-3, .solution-4{
        margin-top: 3rem;
    }
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .solutions{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .solutions{
        height: auto;
    } 
}