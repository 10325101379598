.bmabout {
    padding: 5rem 5rem;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.bmabout-container {
    height: auto;
}

.bmaboutcol-left{
    float: left;
    color: #000;
    width: 50%;
}

.bmaboutcol-left h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.bmaboutcol-full h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}


.bmaboutcol-left p, .bmaboutcol-full p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
    width: 80%;
}

.bmaboutcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.bmaboutcol-left btn{
    margin-top: 1.2rem;
}

.bmaboutcol-right {
    float: right;
    width: 50%;
}

img.bmimg{
    width: 100%;
	margin-top: -50px;
    display: block;
}

img.bmimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
	margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}

@media only screen and (max-width:800px){
    .bmabout{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .bmabout h1{
        font-size: 42px;
    }
    .bmabout h6{
        font-size: 16px;
    }
    .bmabout p{
        font-size: 16px;
        width: 100%;
    }
    img.bmimg{
        display: none;
    }
    img.bmimg2{
        display: block;
        margin-top: -20px;
    }    
    .bmaboutcol-left{
        width: 50%;
    }
    .bmaboutcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .bmabout{
        height: auto;
    }
    .bmabout h1{
        font-size: 24px;
		text-align: center;
    }
    .bmabout p{
        font-size: 12px;
        width: 100%;
		padding: 0;
    }
    img.bmimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.bmimg2{
        display: none;
    }
	.bmabout-container {
		display: flex;
		flex-wrap: wrap;
	}
	.bmaboutcol-full {
		width: 100%;
		order: 1;
	}
    .bmaboutcol-left{
        width: 100%;
		order: 3;
    }
    .bmaboutcol-right{
        width: 100%;
		order: 2;
		margin-bottom: 2rem;
    }
	
	
	
}