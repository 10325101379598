.footer {
    padding: 5rem 5rem 1rem 5rem;
    background-color: #1c1c1c;
    height: auto;
    width: 100%;
    color: white;
}

.footer h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.footer p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #9a9a9a;
}

.footer-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
}

.addf-1 p {
    font-size: 0.9rem;
}

.addf-2 p {
    font-size: 0.9rem;
}

.call1, .call2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.call1:hover {
    color: #F04E31;
}

.call2:hover{
    color: #F04E31;
}

.follow-us p {
    font-size: 0.9rem;
}

i.fab.fa-instagram {
    padding: 0.5rem 0.5rem 0.5rem 0;
}

i.fab.fa-instagram:hover {
    color: #F04E31;
}

i.fab.fa-facebook-f {
    padding: 0.5rem;
}

i.fab.fa-facebook-f:hover {
    color: #F04E31;
}


i.fab.fa-twitter {
    padding: 0.5rem;
}

i.fab.fa-twitter:hover {
    color: #F04E31;
}


i.fab.fa-linkedin-in {
    padding: 0.5rem;
}

i.fab.fa-linkedin-in:hover {
    color: #F04E31;
}

i.fas.fa-map-marker-alt {
    padding: 0.5rem;
}

i.fas.fa-map-marker-alt:hover {
    color: #F04E31;
}

img.call-icon {
    padding-right: 1.2rem;
}

.copyright {
    display: flex;
}

.copy-left {
    width: 50%;
    display: flex;
}

.copy-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

p.left-p1 {
    margin-bottom: 0;
    padding-right: 4rem;
    line-height: 1;
    padding-top: 4rem;
}

p.left-p1:hover {
    color: #F04E31;
}

p.left-p2 {
    margin-bottom: 0;
    padding-right: 4rem;
    line-height: 1;
    padding-top: 4rem;
}

p.left-p2:hover {
    color: #F04E31;
}

p.right-p {
    margin-bottom: 0;
    line-height: 1;
    padding-top: 4rem;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

p.right-p:hover {
    color: #F04E31;
}

p.right-s {
    margin-bottom: 0;
    line-height: 1;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-right: 2rem;
    text-decoration: none;
}

p.right-s:hover {
    color: #F04E31;
}

a{
    text-decoration: none;
}

li {
    list-style: none;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

a.social{
    color: #9a9a9a;
}

.site-links {
    text-decoration: none;
    color: #9a9a9a;
	order: 3;
}

.site-links:hover {
    color: #F04E31;
}

a.call1, a.call2{
    color: #9a9a9a;
    text-decoration: none;
}

ul.addf-1{
    width: 200px;
	order: 1;
}

ul.addf-2{
    width: 250px;
	order: 2;
}
a.copy-left{
    text-decoration: none;
}

.follow-us {
    order: 4;
}
ul.addf-1 ul.addf-2, ul.site-links ul.follow-us{
display:none;
}


@media only screen and (max-width:800px){
    .footer{
        padding: 3rem 1rem;
        height: auto;
    }
    .footer h1{
        font-size: 42px;
        width: 460px;
    }
    .footer p{
        font-size: 16px;
    }
    ul.addf-2{
        margin-left: 0 !important;
        width: 50%;
    }
    ul.addf-1{
        width: 50%;
        height: auto;
    }
    .footer-wrapper{
        width: auto;
        height: auto;
        flex: 0 0 50%;
		flex-wrap: wrap;
    }
    .call1{
        margin-top: 0rem;
    }
    p.right-p, p.left-p1{
        font-size: 12px;
		padding-right: 0;
    }
    p.right-s{
        font-size: 12px;
        padding-right: 1rem;
    }
	p.left-p1 {
		padding-right: 0;
	}
	.site-links {
		width: 50%;
		order: 2;
	}
	.follow-us{
		width: 50%;
		order: 4;
	}
	.footer-wrapper p {
		margin-bottom: 15px;
	}
	ul.addf-2, ul.follow-us{
		display:none;
	}
	ul.addf-1 ul.addf-2, ul.site-links ul.follow-us{
		display:block;
		width: 100%;
	}
	ul.addf-1 ul.addf-2 {
		margin-top: 35px;
	}
	.site-links {
		width: 30%;
	}
	ul.site-links ul.follow-us {
		padding-left: 18px;
		margin-top: 20px;
		color: #fff;
	}
    .copy-left{
        width: 50%;
    }
    .copy-right{
        width: 60%;
    }
}

@media only screen and (max-width:600px){
    .footer{
        height: auto;
    }
    .footer-wrapper{
        flex-direction: column;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .addf-1{
        order: 3;
        text-align: center;
        margin-bottom: 2rem;
    }
    .addf-2{
        order: 4;
        text-align: center;
    }
    ul.addf-2{
        width: auto;
    }
    .call2{
        margin-top: 0;
        justify-content: center;
    }
    .call1{
        justify-content: center;
        margin-top: 0;
    }
    .site-links{
        order: 1;
        text-align: center;
        margin-bottom: 1rem;
    }
    .follow-us{
        order: 2;
        text-align: center;
        margin-bottom: 2rem;
    }
    .copyright{
        flex-direction: column;
        align-items: center;
    }
    .copy-right{
        flex-direction: column;
        align-items: center;
    }
    p.left-p1{
        padding-right: 0;
        font-size: 14px;
    }
    p.right-p{
        justify-content: center;
        padding-top: 2rem;
        font-size: 14px;
    }
    p.right-s{
        justify-content: center;
        padding-top: 2rem;
        font-size: 14px;
        padding-right: 1rem;
    }
    .copy-left{
        width: 100%;
        justify-content: center;
    }
    .copy-right{
        width: 100%;
        justify-content: center;
    }
	.footer p {
		padding-right: 0;
	}
	.footer-wrapper ul {
		width: 100%;
		height: auto;
	}
	img.call-icon {
		padding-right: 1rem;
	}
	.copy-right p.right-p {
		text-align: center;
		line-height: 1.4;
	}
	.site-links {
		order: 1;
	}
	ul.addf-1{
		order: 3;
	}

	ul.addf-2{
		order: 4;
	}

	.follow-us {
		order: 2;
	}
	
	ul.site-links ul.follow-us {
		padding-left: 0px;
	}
	
}

@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .footer{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .footer{
        height: auto;
    } 
	.footer p.right-p {
		padding-right: 0;
	}
	.footer p.left-p1 {
		padding-right: 0;
	}
}