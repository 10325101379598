.VPDesignabout {
    padding: 5rem;
    height: auto;
    background: #fff;
}

.VPDesignabout h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #1c1c1c;
    margin-top: 3rem;
}

.VPDesignabout h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #1c1c1c;
}

.VPDesignabout p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #1c1c1c;
    width: 60%;
}

.VPDesignabout-btn {
    margin-top: 2rem;
}

.VPeft-side{
    float: left;
    width: 50%;
}

.sright-side{
    float: right;
    width: 50%;
}

@media only screen and (max-width:800px){
    .VPDesignabout{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .VPDesignabout h1{
        font-size: 42px;
    }
    .VPDesignabout p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
}

@media only screen and (max-width:600px){
    .VPDesignabout{
        padding: 3rem 1rem;
        height: auto;
    }
    .VPDesignabout h1{
        font-size: 30px;
    }
    .VPDesignabout p{
        font-size: 12px;
        width: 100%;
    }
}