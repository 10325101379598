.contactpage {
    padding: 8rem 5rem;
    height: auto;
    background-image: url('./assets/contact-bg_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow:hidden;
}

.contactpage h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #F04E31;
}

.contactpage h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.contactpage p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 76%;
}

.con-left{
    float: left;
    width: 50%;
}

.con-right{
    float: right;
    width: 50%;
    margin-top: 2rem;
}

.con-right form{
    padding: 0;
}

.con-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4rem;
}

p.callno{
    margin-bottom: 0;
}

.contactpage ul.add-2{
    margin-left: 4rem;
}

.con-right form .form-row{
    display: flex;
    margin: 32px 0;
}

form .form-row .input-data{
    width: 100%;
    height: 40px;
    margin-right: 20px;
    position: relative;
}

form .form-row .textarea{
    height: 70px;
}

.input-data input, .textarea textarea{
    display: block;
    height: 100%;
    width: 100%;
    border: none;
    font-size: 1.2rem;
    border-bottom: 2px solid #BDBDBD;
    background: transparent;
    color: #fff;
}

.textarea textarea{
    resize: none;
    padding-top: 10px;
}

.input-data input:focus ~ label,  .textarea textarea:focus ~ label, 
.input-data input:valid ~ label,  .textarea textarea:valid ~ label{
    transform: translateY(-20px);
    color: #F04E31 !important;
    font-size: 0.8rem;
}

.input-data label{
    position: absolute;
    bottom: 10px;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.3s ease;
}

.textarea label{
    width: 100%;
    bottom: 40px;
}

.input-data .underline{
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
}

.input-data .underline:before, .input-data .underline:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #F04E31;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before, .underline:after,  .textarea textarea:focus ~ .underline:before, .underline:after, 
.input-data input:valid ~ .underline:before, .underline:after,  .textarea textarea:valid ~ .underline:before, .underline:after {
    transform: scaleX(1);
}

.file-upload{
    display: inline-flex;
    align-items: center;
    font-size: 0.8rem;
}

.file-upload__input{
    display: none;
}

.file-upload__button{
    -webkit-appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

span.file-upload__label{
    color: #fff;
}

span.file-upload__label:hover{
    color: #F04E31;
}

svg.attact{
    width: 20px;
    height: 20px;
    color: #fff;
}

svg.attact:hover{
    color: #F04E31;
}

.callc2{
    margin-top: 0;
    justify-content: center;
}
.callc1{
    justify-content: center;
    margin-top: 0;
}

.callc1, .callc2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.callc1:hover {
    color: #F04E31;
}

.callc2:hover{
    color: #F04E31;
}

a.callc1, a.callc2{
    color: #9a9a9a;
    text-decoration: none;
}
.con-right fieldset.msg{
	border-color: red;
}
.con-right fieldset.msg p {
    margin-bottom: 0;
    width: 100%;
    padding: 5px;
}
.con-right fieldset.msg.success {
	border-color: green;
}


@media only screen and (max-width:800px){
    .contactpage{
        padding: 5rem 1rem;
        height: auto;
    }
    .contactpage h1{
        font-size: 42px;
    }
    .contactpage p{
        font-size: 16px;
        width: 90%;
    }
    .con-left, .con-right{
        width: 100%;
    }
    .con-right form .form-row{
        display: block;
    }
    form .form-row .input-data{
        margin: 35px 0!important;
    }
    .callc1{
        margin-top: 0.4rem;
    }
    .con-wrapper .add-1, .con-wrapper .add-2 {
		width: 50%;
		margin: 0 !important;
	}
	.con-wrapper .add-2 {
		padding-left: 10px;
	}
	form .form-row .input-data {
		margin: 35px 0 !important;
		width: 100%;
	}
	.form-row .input-data:first-child {
		padding-right: 10px;
		margin: 0 !important;
	}
	.form-row .input-data:last-child {
		padding-left: 10px;
		margin: 0 !important;
	}
	.form-row .input-data.textarea {
		padding: 0 !important;
	}
	.con-right form .form-row {
		display: flex;
	}
	.con-wrapper {
		margin-bottom: 1rem;
	}
	.contactpage {
		background-size: 100%;
		background-position: bottom;
		background-color: #1C1C1C;
	}
}

@media only screen and (max-width:600px){
    .contactpage h1{
        font-size: 34px;
    }
    .con-left {
		text-align: center;
	}
   .con-wrapper .add-1, .con-wrapper .add-2 {
		width: 50%;
		margin: 0 !important;
	}
	.con-wrapper {
		display: none;
	}
	.contactpage p {
		width: 100%;
		padding: 0;
	}
	.form-row.submit-btn {
		text-align: center;
	}
	.con-right form .form-row {
	display: block;
}
form .form-row .input-data ,.form-row .input-data:first-child,.form-row .input-data:last-child {
	margin: 35px 0 !important;
	width: 100% !important;
	padding:0 !important;
}    
}