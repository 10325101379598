.pnf {
    padding: 5rem;
    background: #1c1c1c;
    height: auto;
}

.pnf h1 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    line-height: 1.2;
    color: #c5c5c5;
    padding-top: 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 2rem;
    font-weight: 700;
}

.pnf p {
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    padding-right: 15px;
    color: #808080;
    text-align: center;
    width: 40%;
    font-weight: 300;
}

.pnf-section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    top: 60%;
    left: 50%;
}

img.pnf-img{
    height: 250px;
}

@media only screen and (max-width:900px){
	img.pnf-img{
        height: 180px;
    }
    .pnf-p{
        width: 100%;
    }
}
@media only screen and (max-width:600px){
    .pnf h1 {
        font-size: 1.4rem;
    }
    .pnf-p{
        width: 90%;
    }
    img.pnf-img{
        height: 120px;
    }
}