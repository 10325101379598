.sdservice {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
} 

.sdservice h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    text-align: center;
}

.sdservice h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.sdservice p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
}

.sdservice-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.sdservice-1 p {
    font-size: 0.9rem;
}

.sdservice-2 p {
    font-size: 0.9rem;
}

.sdservice-3 p {
    font-size: 0.9rem;
}

.sdservice-4 p {
    font-size: 0.9rem;
}

ul.sdservice-1 {
    margin-right: 2rem;
    margin-left: 1rem;
    box-shadow: 2px 2px 2px 2px #eee;
    width: 22%;
    height: 300px;
    padding: 0.2rem;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    background: #f5f5f5;
}

ul.sdservice-2, ul.sdservice-3, ul.sdservice-4 {
    margin-right: 2rem;
    box-shadow: 2px 2px 2px 2px #eee;
    width: 21%;
    height: 300px;
    padding: 0.2rem;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    background: #f5f5f5;
}

p.ec-desc {
    color: #000;
    height: 100px;
}

h1.ecno {
    color: #F04E31;
}

.ecleft-desc{
    float: left;
    width: 50%;
}

.ecright-desc{
    float: right;
    width: 50%;
    align-items: baseline;
    padding-top: 2.4rem;
}

.ecright-desc p {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 0px;
    color: black;
    width: 65%;
}

.ecdesc{
    margin-top: 6rem;
	display: block;
	overflow: hidden;
}

.ec-logos{
    margin-top: 3rem;
}

.eclogo1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.eclogo2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
img.eclogo {
    width: auto;
    display: block;
    padding: 1rem;
    filter: grayscale(100%);
}
img.eclogo:hover{
    filter: grayscale(0%);
}
.sdservice-wrapper ul {
	padding: 0 1.5rem !important;
}

@media only screen and (max-width:800px){
    .sdservice{
        padding: 3rem 1rem;
        height: auto;
    }
    .sdservice h1{    
        font-size: 34px;
		margin-bottom: 10px
    }
    .sdservice h6{
        font-size: 20px;
    }
    .sdservice p{
        font-size: 16px;
        width: 100%;
    }
    .sdservice-wrapper{
        width: auto;
        height: auto;
        flex: 0 0 50%;
    }
    ul.sdservice-1{
        width: 40%;
        margin-left: 0;
    }
    ul.sdservice-2, ul.sdservice-3, ul.sdservice-4{
        width: 40%;
    }
    .ec-logos{
        margin-top: 1rem;
    }
    .eclogo1{
        flex-wrap: nowrap;
        margin-bottom: 1rem;
    }
    .eclogo2{
        flex-wrap: nowrap;
    }
    img.eclogo{
        padding: 0.5rem;
    }
    .ecleft-desc{
        width: 100%;
    }
    .ecright-desc{
        width: 100%;
        padding-top: 0;
    }
    .sdservice-wrapper ul {
		width: 44%;
		margin-bottom: 20px;
	}
	.sdservice-4 {
		margin-right: 0 !important;
	}
	.sdservice-2 {
		margin-right: 0 !important;
	}
	.ecdesc {
		margin-top: 2rem;
	}
}

@media only screen and (max-width:600px){
    .sdservice{
        height: auto;
    }
    .sdservice h1{
        font-size: 3rem;
        margin-bottom: 1rem;
    }
    .sdservice p{
        font-size: 12px;
        width: 100%;
    }
    ul.sdservice-1, ul.sdservice-2, ul.sdservice-3, ul.sdservice-4{
        width: 100%;
        height: auto;
		margin-right: 0px;
		padding: 20px 15px !important;
    }
    p.ec-desc{
        height: auto;
        margin-bottom: 0;
    }
    img.eclogos2 {
        display: block;
        width: 445px;
    }
    .ec-logos{
        margin-top: 1rem;
    }
}
@media only screen and (min-device-width : 801px) and (max-device-width : 1124px){
	.sdservice-wrapper ul {
		padding: 0 10px !important;
		width: 47%;
		margin-bottom: 20px;
	}
	.sdservice-1 {
		margin-left: 0 !important;
	}
	.sdservice-2, .sdservice-4 {
		margin-right: 0 !important;
	}
}
@media only screen and (max-width:767px){
.eclogo1, .eclogo2 {
	flex-wrap: wrap;
	margin-bottom: 1rem;
}
}