.hero-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1c1c1c;
}

.hero-container {
    height: 100vh;
}

.col-left{
    float: left;
    color: #fff;
    padding: 5rem 0 5rem 5rem;
    width: 50%;
}

.col-left h1 {
    margin-bottom: 2rem;
    font-size: 4rem;
    line-height: 1.2;
}

.col-left p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #9a9a9a;
}

.col-left btn{
    margin-top: 1.2rem;
}

.col-right {
    float: right;
    width: 50%;
}

.hero-social{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -8rem;
    margin-right: 5rem;
}

i.fab.fa-instagram.hsocial{
    width: 17px;
    padding: 0.5px;    
}

i.fab.fa-facebook-f.hsocial{
    width: 17px;
    padding: 0.5px;    
}
i.fas.fa-map-marker-alt.hsocial{
    width: 17px;
    padding: 0.5px;    
}

i.fab.fa-linkedin-in.hsocial{
    width: 17px;
    padding: 0.5px;    
}

a.hsocial{
    color: #fff;
    margin:0.5rem;
}

/* .parent{
    position: relative;
} */

.vector{
    position: absolute;
    left: 65rem;
	z-index: 1;
}

img.vector{
    width: 200px;
    height: 200px;
    animation-name: rotate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
}

@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}


@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .hero-section{
        height: 100vh;
    }
    
}
@media only screen and (min-device-width : 1025px) and (max-device-width : 1280px){
      .hero-section{
        height: 100vh;
    }  
    .hero-container{
        height: 100vh;
    }
    .col-left {
        width: 60%;
    }
    .col-right{
        width: 40%;
    }
    .blob{
        width: 100%;
        margin-top: 5rem;
    }
    .hero-social{
        margin-top:-90px;
        margin-right: 10px;
    }
    .vector{
        top: 20%;
		right: 4%;
		left: auto;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .hero-section{
        height: 100vh;
    }  
    .hero-container{
        height: 100vh;
    }
    .col-left {
        padding: 4rem 0 0 1rem;
        width: 60%;
    }
    .col-left h1{
        font-size: 42px;
    }
    .col-left p{
        font-size: 16px;
    }
    .col-right{
        width: 40%;
    }
    .blob{
        width: 100%;
        margin-top: 5rem;
    }
    .hero-social{
        margin-top: 0;
        margin-right: 10px;
    }
    img.vector{
        height: 100px;
        width: 100px;
    }
    .vector{
        top: 30%;
		right: 4%;
		left: auto;
    }
}

@media only screen and (min-device-width : 600px) and (max-width:800px) {
    /* For tablets: */
    .hero-section{
        height: 75vh;
    }
    .hero-container{
        height: 75vh;
    }
    .col-left {
        padding: 1rem 0 0 1rem;
        width: 60%;
    }
    .col-left h1{
        font-size: 42px;
    }
    .col-left p{
        font-size: 16px;
    }
    .col-right{
        width: 40%;
    }
    .blob{
        width: 100%;
        margin-top: 0rem;
    }
    .hero-social{
        margin-top: 0;
        margin-right: 10px;
    }
    img.vector{
        height: 100px;
        width: 100px;
    }
    .vector{
        top: 18%;
		right: 4%;
		left: auto;
    }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width:600px) {
    /* For mobile phones: */
      .col-left {
        width: 100%;
		padding: 110px 15px 20px 15px;
	}
    .col-right{
        width: 100%;
    }
    img.vector {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 17%;
	right: 10%;
	left: auto;
}
	.hero-section {
    height: auto;
    display: block;
    width: 100%;
}
.hero-container {
    height: auto;
	display:block;
	overflow: hidden;
}
.col-left p {
    margin-bottom: 10px;
	font-size: 12px;
line-height: 1.2;
}

.col-left h1 {
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 1.2;
}
.hero-btn {
    margin-top: 18px;
}

.blob {
    width: 100%;
    margin-top: 20px;
}
.hero-social {
	margin-top: 0;
	margin-right: 0;
	float: right;
margin-bottom: 25px;
display: inline-block;
}


  }
  
