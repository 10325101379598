.hjabout {
    padding: 5rem 5rem;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.hjabout-container {
    height: auto;
}

.hjaboutcol-left{
    float: left;
    color: #000;
    width: 50%;
}

.hjaboutcol-left h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.hjaboutcol-full h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}


.hjaboutcol-left p, .hjaboutcol-full p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
    width: 80%;
}

.hjaboutcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.hjaboutcol-left btn{
    margin-top: 1.2rem;
}

.hjaboutcol-right {
    float: right;
    width: 50%;
}

img.hjimg{
    width: 100%;
	margin-top: -50px;
    display: block;
}

img.hjimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
	margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}

@media only screen and (max-width:800px){
    .hjabout{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .hjabout h1{
        font-size: 42px;
    }
    .hjabout h6{
        font-size: 16px;
    }
    .hjabout p{
        font-size: 16px;
        width: 100%;
    }
    img.hjimg{
        display: none;
    }
    img.hjimg2{
        display: block;
        margin-top: -20px;
    }    
    .hjaboutcol-left{
        width: 50%;
    }
    .hjaboutcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .hjabout{
        height: auto;
    }
    .hjabout h1{
        font-size: 24px;
		text-align: center;
    }
    .hjabout p{
        font-size: 12px;
        width: 100%;
		padding: 0;
    }
    img.hjimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.hjimg2{
        display: none;
    }
	.hjabout-container {
		display: flex;
		flex-wrap: wrap;
	}
	.hjaboutcol-full {
		width: 100%;
		order: 1;
	}
    .hjaboutcol-left{
        width: 100%;
		order: 3;
    }
    .hjaboutcol-right{
        width: 100%;
		order: 2;
		margin-bottom: 2rem;
    }
	
	
	
}