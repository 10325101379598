.GDesign-desc2 {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/about-black_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.GDesign-desc2 h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #ffffff;
    margin-top: 3rem;
    text-transform: uppercase;
}

.GDesign-desc2 h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #ffffff;
}

.GDesign-desc2 p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #ffffff;
}

.GDesign-desc2-btn {
    margin-top: 2rem;
}

li.lda-items { 
    color: #ffffff;
    font-size: 1rem;
}

.dev-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

.lda-service-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.lda-service-1 p {
    font-size: 0.9rem;
}

.lda-service-2 p {
    font-size: 0.9rem;
}

.lda-service-3 p {
    font-size: 0.9rem;
}

.lda-service-4 p {
    font-size: 0.9rem;
}
.lda-service-5 p {
    font-size: 0.9rem;
} 

ul.lda-service-1, ul.lda-service-4 {
    margin-right: 2rem;
    margin-left: 1rem;
    box-shadow: 2px 2px 2px 2px #121212;
    width: 30%;
    height: 350px;
    padding: 0.2rem;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
}

ul.lda-service-2, ul.lda-service-3, ul.lda-service-5 {
    margin-right: 2rem;
    box-shadow: 2px 2px 2px 2px #121212;
    width: 30%;
    height: 350px;
    padding: 0.2rem;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
}

p.ec-desc {
    color: #ffffff;
    height: 100px;
}

h1.ecno {
    color: #F04E31;
}

.lda-service-wrapper ul {
	padding: 0 10px !important;
}

@media only screen and (max-width:800px){
    .GDesign-desc2{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .GDesign-desc2 h1{
        font-size: 42px;
    }
    .GDesign-desc2 p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
	li.lda-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.lda-items:last-child {
		margin: 0;
	}

    .lda-service-wrapper{
        width: auto;
        height: auto;
        flex: 0 0 50%;
    }
    ul.lda-service-1, ul.lda-service-4{
        width: 40%;
        margin-left: 0;
        height: 400px;
    }
    ul.lda-service-2, ul.lda-service-3, ul.lda-service-4, ul.lda-service-5{
        width: 40%;
        height: 400px;
    }

    .lda-service-wrapper ul {
		width: 44%;
		margin-bottom: 20px;
	}
	.lda-service-2, .lda-service-5 {
		margin-right: 0 !important;
	}
}

@media only screen and (max-width:600px){
    .GDesign-desc2{
        padding: 3rem 1rem;
        height: auto;
    }
    .GDesign-desc2 h1{
        font-size: 30px;
    }
    .GDesign-desc2 p{
        font-size: 12px;
        width: 100%;
    }
    li.dev-items {
		padding: 0;
	}

    ul.lda-service-1, ul.lda-service-2, ul.lda-service-3, ul.lda-service-4, ul.lda-service-5{
        width: 100%;
        height: auto;
		margin-right: 0px;
		padding: 20px 15px !important;
    }
}

@media only screen and (min-device-width : 801px) and (max-device-width : 1124px){
	.lda-service-wrapper ul {
		padding: 0 10px !important;
		width: 47%;
		margin-bottom: 20px;
	}
	.lda-service-1 {
		margin-left: 0 !important;
	}
	.lda-service-2, .lda-service-4, .lda-service-5 {
		margin-right: 0 !important;
	}
}