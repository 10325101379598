.portfolio {
    padding: 7rem 5rem;
    height: auto;
    background-image: url('./assets/about-red_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#FF4426;
}

.portfolio h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.2;
    color: #fff;    
    text-transform: uppercase;
    text-align: center;
    width: 93%;
}

.portfolio h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.portfolio p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
}

@media only screen and (max-width:800px){
    .portfolio{
        padding: 5rem 1rem;
        height: auto;
		background-size: cover;
		background-color: #FF4426;
		background-position: top center;
    }
    .portfolio h1{
        font-size: 30px;
        width: 100%;
    }
    .portfolio p{
        font-size: 16px;
    }

}

@media only screen and (max-width:600px){
    .portfolio{
        height: auto;
        background-size: 218% 100%;
		background-color: #FF4426;
		background-position: top center;
    }
    .portfolio h1{
        font-size: 20px;
        width: 100%;
    }
    .portfolio p{
        font-size: 16px;
    }
}