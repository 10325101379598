.sdwork {
    padding: 5rem 5rem;
    height: auto;
    background: #121212;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.sdworks { 
    height: auto;
    background: #121212;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.sdwork-container {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sdworkcol-left{
    color: #F04E31;
    width: 50%;
    font-size: 3rem;
}

.sdworkcol-right{
    float: right;
    color: #F04E31;
    width: 50%;
    margin-top: 5rem;
}

.sdworkcol-left h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.sdworkcol-full h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.sdleft-desc{
    float: left;
    width: 50%;
    color: #fff;
    margin-top: -1rem;
}
.sd-work{
    margin-bottom: 4rem;
    display: flex;
}
.sdworkh{
    font-size: 3rem;
}
.sda{
    color: #fff;
}
.sda:hover{
    text-decoration: underline;
}
.sdright-desc{
    float: right;
    width: 50%;
    align-items: baseline;
}
.sdwork-right{
    float: right;
    width: 50%;
    margin-top: 1.5rem;
}
.sdworkcol-left p, .sdworkcol-full p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    width: 80%;
}

.sdworkcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.sdworkcol-left btn{
    margin-top: 1.2rem;
}

.sdworkcol-right {
    float: right;
    width: 50%;
}

img.sbimg{
    width: 100%;
    margin-top: -50px;
    display: block;
}

.sdwimg{
    height: 300px;
    width: 540px;
    box-shadow: 2px 2px 2px 2px #2a2a2a;
}

img.sbimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}
.sdw-btn{
    margin-top: 2rem;
}

@media only screen and (max-width:800px){
    .sdwork{
        padding: 3rem 1rem;
        height: auto;
        overflow: hidden;
    }
    .sdwork h1{
        font-size: 42px;
    }
    .sdwork h6{
        font-size: 16px;
    }
    .sdwork p{
        font-size: 16px;
        width: 100%;
    }
    img.sbimg{
        display: none;
    }
    img.sbimg2{
        display: block;
        margin-top: -50px;
    }    
    .sdworkcol-left{
        width: 50%;
    }
    .sdworkcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .sdwork{
        height: auto;
    }
    /* .sdwork h1{
        font-size: 24px;
        text-align: center;
    } */
    .sdwork p{
        font-size: 12px;
        width: 100%;
        padding: 0;
    }
    img.sbimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.sbimg2{
        display: none;
    }
    .sdwork-container {
        display: flex;
        flex-wrap: wrap;
    }
    .sdworkcol-full {
        width: 100%;
        order: 1;
    }
    .sdworkcol-left{
        width: 100%;
        order: 3;
    }
    .sdworkcol-right{
        width: 100%;
        order: 2;
        margin-bottom: 2rem;
    }
    .sd-work{
        flex-direction: column;
    }
    .sdworks{
        flex-direction: column-reverse;
    }
    .sdwimg{
        width: 20rem;
        height: auto;
    }
    .sdleft-desc, .sdright-desc{
        width: 100%;
    }
}
