.seosteps {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/about-black_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.seosteps h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.seosteps h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.seosteps p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
}

.seosteps-left{
    float: left;
    width: 50%;
}

.seosteps-right{
    float: right;
    width: 50%;
    align-content: flex-end;
}

li.seosteps-items {
    color: #fff;
    font-size: 1rem;
}

.seosteps-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

.scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
  }
  
.scrollbar-warning::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}
  
.scrollbar-warning::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    background-color: #F5F5F5; 
}
  
.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F04E31; 
}

li.seosteps-items {
    color: #fff;
    font-size: 1rem;
}

li.seosteps-item {
    color: #bfbfbf;
    list-style: disc;
    font-size: 1rem;
    margin-left: 2rem;
    margin-top: 1rem;
}

::marker  {
    color: #F04E31;
}

@media only screen and (max-width:800px){
    .seosteps{
        padding: 3rem 1rem;
        height: auto;
    }
    .seosteps h1{
        font-size: 42px;
    }
    .seosteps p{
        font-size: 16px;
    }
    .seosteps-left{
        width: 40%;
    }
    .seosteps-right{
        width: 60%;
    }
    .seop{
        display: none;
    }
	.seosteps-list {
		overflow-y: unset;
		white-space: normal;
		height: auto;
	}
}

@media only screen and (max-width:600px){
    .seosteps{
        height: auto;
    }
    .seosteps h1{
        font-size: 24px;
    }
    .seosteps p{
        font-size: 16px;
    }
    .seosteps-left{
        width: 100%;
    }
    .seosteps-right{
        width: 100%;
    }
	li.seosteps-item {
		margin-left: 1rem;
		padding-left: 0;
	}
}