.wdabout {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/webdesign-bg_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.wdabout h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    margin-top: 3rem;
}

.wdabout h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.wdabout p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 70%;
}

p.wdp{
    margin-bottom: 2rem;
}

.wdabout-btn {
    margin-top: 2rem;
}

@media only screen and (max-width:800px){
    .wdabout{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .wdabout{
        font-size: 42px;
    }
    .wdabout p{
        font-size: 12px;
        width: 80%;
    }
}

@media only screen and (max-width:600px){
    .wdabout h1 {
        font-size: 34px;
        margin-bottom: 15px;
    }
    .wdabout p {
        font-size: 12px;
        width: 100%;
        margin-bottom: 20px;
    }
    .wdabout-btn {
        margin-top: 10px;
    }
}