.GDesignabout {
    padding: 5rem;
    height: auto;
    background-image: url('./assets/graphic_design_desc_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.GDesignabout h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    margin-top: 3rem;
}

.GDesignabout h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.GDesignabout p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 60%;
}

.GDesignabout-btn {
    margin-top: 2rem;
}

@media only screen and (max-width:800px){
    .GDesignabout{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
    }
    .GDesignabout h1{
        font-size: 42px;
    }
    .GDesignabout p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
}

@media only screen and (max-width:600px){
    .GDesignabout{
        padding: 3rem 1rem;
        height: auto;
    }
    .GDesignabout h1{
        font-size: 30px;
    }
    .GDesignabout p{
        font-size: 12px;
        width: 100%;
    }
}