.tfdesc {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
    width: 100%;
}

.tfdesc h1 {
    margin-bottom: 2rem;
	margin-top: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.tfdesc h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.tfdesc p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
    width: 100%;
}

img.tfdescri {
    width: 470px;
}

.tfdesc-cat{
    display: flex;
    margin-top: 4rem;
}

.tfdesc-left{
    float: left;
    width: 70%;
    display: flex;
}

.tfdesc-right{
    float: right;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: top;
}

img.tfdesc2ri {
    width: 310px;
    height: 400px;
    margin-left: 7rem;
}

.tfdesc-cat2{
    display: flex;
    margin-top: 5rem;
}

.tfdesc2-left{
    float: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

.tfdesc2-right{
    float: right;
    width: 40%;
    display: flex;
}

@media only screen and (max-width:800px){
    .tfdesc{
        padding: 3rem 1rem;
        height: auto;
    }
    .tfdesc h1{
        font-size: 42px;
		margin-top: 0;	
    }
    .tfdesc h6{
        font-size: 16px;
    }
    .tfdesc p{
        font-size: 16px;
        width: 100%;
    }
    .tfdesc-left{
        width: 32%;
    }
    .tfdesc-right{
        width: 68%;
    }
    .tfdesc2-right{
        padding-top: 2rem;
    }
    img.tfdescri {
		width: 400px;
		height: auto;
		position: absolute;
		left: -135px;
	}
    img.tfdesc2ri{
        width: 280px;
        height: 330px;
        margin-top: 0rem;
        margin-left: 0;
    }
	.tfdesc-cat {
		display: flex;
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media only screen and (max-width:600px){
    .tfdesc{
        height: auto;
    }
    .tfdesc h1{
        font-size: 24px;
    }
    .tfdesc p{
        font-size: 12px;
        width: 100%;
		padding: 0;
    }
    .tfdesc-cat{
        flex-direction: column-reverse;
        margin-top: 0;
    }
    .tfdesc-left{
        width: 100%;
        justify-content: center;
    }
    .tfdesc-right{
        width: 100%;
    }
    .tfdesc-cat2{
        flex-direction: column;
    }
    .tfdesc2-left{
        width: 100%;
    }
    .tfdesc2-right{
        width: 100%;
        justify-content: center;
    }
	img.tfdesc2ri{
        margin-top: 2rem;
    }
	img.tfdescri {
		width: 100%;
		height: auto;
		position: relative;
		left: 0;
	}
}    