.arexp {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
    width: 100%;
}

.arexp h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    text-align: center;
}

.arexp h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.arexp p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
    width: 60%;
}

img.ari{
    width: 1200px;
}

.ar-video{
    height: auto;
    width: 100%;
}

@media only screen and (max-width:800px){
    .arexp{
        padding: 3rem 1rem;
        height: auto;
    }
    .arexp h1{
        font-size: 42px;
    }
    .arexp h6{
        font-size: 16px;
    }
    .arexp p{
        font-size: 16px;
        width: 100%;
		padding: 0 !important;
    }
    img.ari{
        width: 720px;
    }
}

@media only screen and (max-width:600px){
    .arexp{
        height: auto;
    }
    .arexp h1{
        font-size: 30px;
    }
    .arexp p{
        font-size: 12px;
        width: 100%;
    }
    img.ari{
        width: 450px;
    }

}