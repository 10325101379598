.sitemap-page {
    padding: 5rem 10rem;
    height: auto;
}

.sitemap-page h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #181818;
    font-weight: 600;
    font-style: normal;
}
.sitemap-page h2{
    color: #F04E31;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
}

.sitemap-page h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #F04E31;
}

.sitemap-page p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #181818;
}
.line{
    height: 5px;
    width: 100%;
    max-width: 75rem;
    align-self: flex-start;
    display: flex;
    justify-self: flex-start;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    border-top: 1px dashed #181818;
}
.title{
    margin: 1rem 0rem;
}
.site-data{
    margin: 3rem 0rem 6rem 0rem;
}
.siteprojects{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    height: 21rem;
}
.data{
    margin: 1rem 0rem;
}
.data:hover {
    color: #F04E31;
}

@media only screen and (max-width:800px){
    .sitemap-page{
        padding: 3rem 2rem;
        height: auto;
    }
    .sitemap-page h1{
        font-size: 42px;
    }
    .sitemap-page p{
        font-size: 16px;
    }
    .siteprojects{
        height: 30rem;
    }
}

@media only screen and (max-width:600px){
    .sitemap-page{
        height: auto;
    }
	.sitemap-page h1 {
    font-size: 26px;
	margin-bottom: 10px;
    }
    .siteprojects{
        height: auto;
    }
}