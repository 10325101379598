.our-services {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/bg-white-mainpage_One5Digital.png');
}

.our-services h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}
.our-services h1 span{
	margin-left: 0;
	}
.our-services h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.our-services p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

p.services-desc{
    width: 50%;
}


.service-wrapper {
    display: flex;
    margin-top: 3.5rem;
    flex-wrap: wrap;
}

.service-1 p {
    font-size: 0.9rem;
    height: 84px;
}

.service-2 p {
    font-size: 0.9rem;
    height: 84px;
}

.service-3 p {
    font-size: 0.9rem;
    height: 84px;
}

.service-4 p {
    font-size: 0.9rem;
    height: 84px;
}

.service-1, .service-2, .service-3, .service-4{
    width: 25%;
    height: auto;
}

@media only screen and (max-width:1280px) {
    /* For safari landscape: */
   .service-1 p,.service-2 p,.service-3 p,.service-4 p{
		height:auto;
   }
    
}

@media only screen and (max-width:800px){
    .our-services{
        padding: 3rem 1rem;
    }
    .our-services h1{
        font-size: 42px;
    }
    .our-services p{
        font-size: 16px;
    }
    p.services-desc{
        width: 100%;
    }
    .service-1, .service-2, .service-3, .service-4{
        width: auto;
        height: auto;
        flex: 0 0 50%;
    }
    ul.service-3, ul.service-4{
        margin-top: 4rem;
    }
    .service-btn{
        margin-top: 3rem;
    }
}
@media only screen and (min-width:601px) and (max-width:799px){
.our-services h1 {
    font-size: 36px;
}
}

@media only screen and (max-width:600px){
    
    p.service-desc{
        height: auto;
    }
    .our-services h1{
        font-size: 22px;
    }
	
    .our-services p{
        font-size: 12px;
		margin-bottom:10px;
    }
    .service-wrapper{
        flex-direction: column;
    }
    .service-btn{
        margin-top: 0;
    }
    ul.service-2, ul.service-3, ul.service-4{
        margin-top: 3rem;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
    
}