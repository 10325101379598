.SMDesigncontact {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/bg-red_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.VDesigncontact h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    width: 80%;
}

.SMDesigncontact h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.SMDesigncontact p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
}

.left-SMDesigncontact{
    float: left;
    width: 60%;
}

.right-contact{
    float: right;
    width: 40%;
    margin-top: -7rem;
}

.right-desc p {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 0px;
}

.btn--white:hover {
    color: #F04E31;
    background-color: #fff;
}

@media only screen and (max-width:800px){
    .SMDesigncontact{
        padding: 3rem 1rem;
        height: auto;
    }
    .SMDesigncontact h1{
        font-size: 42px;
        width: 100%;
    }
    .SMDesigncontact p{
        font-size: 14px;
        width: 100%;
    }
    .left-SMDesigncontact{
        width: 70%;
    }
}

@media only screen and (max-width:600px){
    .SMDesigncontact{
        height: auto;
    }
    .SMDesigncontact h1{
        font-size: 24px;
        width: 100%;
    }
    .SMDesigncontact p{
        font-size: 12px;
        width: 100%;
        margin-bottom: 10px;
    }
    .left-SMDesigncontact {
		width: 100%;
	}
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .SMDesigncontact{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .SMDesigncontact{
        height: auto;
    } 
}