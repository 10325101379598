.servicepage {
    padding: 5rem 5rem;
    height: auto;
    background: #fff;
    overflow: hidden;
}

.servicepage h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
}

.servicepage h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.servicepage p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

.left-servicedesc{
    float: left;
    width: 60%;
}

.right-servicedesc{
    float: right;
    width: 40%;
    align-items: baseline;
    padding-top: 2.4rem;
}

.service-desc p {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 0px;
    width: 80%;
}

.servicepage-1 p {
    font-size: 0.9rem;
}

.servicepage-2 p {
    font-size: 0.9rem;
}

.servicepage-3 p {
    font-size: 0.9rem;
}

.servicepage-4 p {
    font-size: 0.9rem;
}

.servicepage-5 p {
    font-size: 0.9rem;
}

.s1-desc {
    float: left;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.s2-desc {
    float: right;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding-right: 4rem;
}

.s3-desc{
    float: left;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.s4-desc {
    float: right;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding-right: 4rem;
    margin-top: 2rem;

}

.s5-desc {
    float: left;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.s1-img {
    float: right;
    width: 50%;
    margin-top: 2rem;
}
.s2-img  {
    float: left;
    width: 50%;
    margin-top: 2rem;
}
.s3-img{
    float: right;
    width: 50%;
    margin-top: 2rem;
}
.s4-img{
    float: left;
    width: 50%;
    margin-top: 2rem;
}
.s5-img{
    float: right;
    width: 50%;
    margin-top: 2rem;
}
img.simg{
    width: 100%;
}
.servicepage .p2-img, .servicepage .p4-img, .servicepage .p6-img {
padding-right: 25px;
}
.servicepage .p1-img, .servicepage .p3-img, .servicepage .p5-img {
padding-left: 25px;
}
.servicepage .p2-desc, .servicepage .p4-desc, .servicepage .p6-desc {
padding-right: 0rem;
padding-left: 25px;
}
.servicepage .p1-desc, .servicepage .p3-desc, .servicepage .p5-desc {
padding-right: 0rem;
padding-right: 25px;
}
.servicepageall {
display: flex;
width: 100%;
align-items: center;
margin-top:2rem;
}
.servicepageall > div {
margin-top: 0;
height: auto;
}
.servicepage h1 span{
margin-left:0;
}
.service-desc {
margin-bottom: 4rem !important;
}

.servicepageall > div{
width: 50% !important;
}

@media only screen and (max-width:800px){
    .servicepage{
        padding: 3rem 1rem;
        height: auto;
    }
    .servicepage h1{
        font-size: 42px;
        margin-bottom: 1rem;
    }
    .servicepage p{
        font-size: 12px;
    }
    .servicepage .left-desc{
        width: 50%;
    }
    .servicepage .right-desc{
        width: 50%;
        padding-top: 1rem;
    }
    img.simg{
        width: 360px;
    }
    .p1-desc, .p3-desc{
        width: 50%;
        height: 270px;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    .p5-desc{
        height: 300px;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    .p2-desc, .p4-desc, .p6-desc{
        height: 270px;
        width: 300px;
        margin-top: 2rem;
        padding: 0;
        margin-left: 2rem;
    }
    .servicepage-wrapper{
        display: flex;
        flex: 50%;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
    }

    .servicepage .p1-img, .servicepage .p3-img, .servicepage .p5-img {
		order:2;
	}

}

@media only screen and (max-width:600px){
    .servicepage{
        height: auto;
    }
    .servicepage h1{
        font-size: 30px;
    }
    .servicepage-wrapper{
        flex-direction: column;
    }
    .servicepage-1, .servicepage-2, .servicepage-3, .servicepage-4, .servicepage-5, .servicepage-6{
        flex-direction: column;
    }
    .servicepage .p1-desc, .servicepage .p2-desc, .servicepage .p3-desc, .servicepage .p4-desc, .servicepage .p5-desc, .servicepage .p6-desc{
        order: 2;
        align-items: start;
        width: 100%;
        text-align: left;
        margin-left: 0;
        margin-top: 0;
    }
    .servicepage .p1-img, .servicepage .p2-img, .servicepage .p3-img,.servicepage  .p4-img, .servicepage .p5-img, .servicepage .p6-img{
        order: 1;
    }
    img.simg{
        width: 100%;
    }
    .left-servicedesc {
		float: left;
		width: 100%;
	}
	.servicepageall > div {
		width: 100% !important;
		padding: 0 !important;
		text-align: left !important;
	}
	.servicepage h6 {
		font-size: 1.4rem;
		margin-top: 10px;
	}
}
