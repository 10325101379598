.tnc {
    padding: 3rem 5rem 5rem 5rem;
    background-image: url('./assets/tnc_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: auto;
}

.tnc h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #f04e31;
    padding-top: 1rem;
    text-align: center;
    background: #fff;
    border-radius: 30px;
}

.tnc h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #1c1c1c;
}

.tnc p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    padding-right: 15px;
    color: #000;
}

.tnc-card{
    height: 80vh;
    border-radius: 30px;
    background: #fff;
}

.terms{
    height: 60vh;
    overflow: hidden;
    margin: 0 4rem;
    overflow-y: scroll;
    white-space: normal;
    width: 90%;
}

.scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
  }
  
.scrollbar-warning::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}
  
.scrollbar-warning::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    background-color: #F5F5F5; 
}
  
.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F04E31; 
}

.tnc-contact{
    text-decoration: none;
    color: #000;
}
@media only screen and (max-width:800px){
	.tnc {
		padding: 3rem 1rem;
	}
	.terms {
		margin: 0 00px;
		width: 100%;
		padding: 0 15px;
	}
}
@media only screen and (max-width:600px){
.tnc h1 {
	font-size: 24px;
}
}