.project {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
}

.project h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #F04E31;
}

.project h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 300;
}

.project p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
    width: 60%;
}

.pro-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

img.proimg{
    width: 100%;
}

.prorow ul {
    margin-left: -15px;
     margin-right: -15px;
 }
 .prorow {
     width: 100%;
 }
ul.prorow1-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.yourpro {
    height: 400px;
    width: 100%;
    background-image: url('./assets/pro-bg_One5Digital.png');
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.yourpro h4 {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 1rem;
}

li.projli{
    width: 25%;
    height: auto;
}
.yourpro ~ h6 {
	margin-top: 10px;
}

.videopro {
    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;
    visibility: hidden;
    margin-top: -25rem;
    top: -1.5rem;
}
.imgpro{
    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;
    visibility: hidden;
    margin-top: -25rem;
    top: -1.5rem; 
}
.pro1:hover .proimg{
    visibility: hidden;
}
.pro1:hover .videopro{
    visibility: visible;
}
.pro1:hover .imgpro{
    visibility: visible;
}

@media only screen and (max-width:800px){
    .project{
        padding: 3rem 1rem;
        height: auto;
    }
    .project h1{
        font-size: 42px;
    }
    .project h6{
        font-size: 16px;
    }
    .project p{
        font-size: 16px;
        width: 100%;
    }
    img.proimg{
        width: 100%;
        height: auto;
    }
    li.projli{
        width: 33.33%;
        height: auto;
    }
    .prorow-4, .prorow-5, .prorow-6, .prorow-7{
        display: none;
    }
    .projli.yourpromain {
		display: none;
	}

}

@media only screen and (max-width:500px){
    .project{
        height: auto;
    }
    .project h1{
        font-size: 30px;
    }
    .project p{
        font-size: 12px;
        width: 100%;
    }
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .project{
        height: auto;
    }
    
}
@media only screen and (max-device-width : 767px){
	.yourpro h4 {
		font-size: 1.3rem;
	}
	li.projli {
		width: 50%;
	}
	.project h6{
        font-size: 14px;
    }
    .project p{
        font-size: 14px;
        width: 100%;
    }
	li.projli:nth-child(2n+1) {
		padding-right: 5px;
	}
	li.projli:nth-child(2n) {
		padding-left: 5px;
	}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .project{
        height: auto;
    } 
    .yourpro {
		height: 87%;
		width: 100%;
	}
	.yourpro h4 {
		color: #fff;
		font-size: 1rem;
		margin-bottom: 1rem;
	}
	.project {
		padding: 5rem 1rem;
	}
	.prorow ul li {
		padding-left: 10px;
		padding-right: 10px;
	}
	.prorow ul {
		margin-right: -10px;
		margin-left: -10px;
	}
}