.benefits {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/ecombg_One5Digital.png');
    background-size: cover;
}

.benefits h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.benefits h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #fff;
}

p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
}
.benefits p {
    max-width: 60%;
}

.benefit-wrapper {
    display: flex;
    flex: 1;
    margin-top: 4rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.benefit-1 p {
    font-size: 0.9rem;
}

.benefit-2 p {
    font-size: 0.9rem;
}

.benefit-3 p {
    font-size: 0.9rem;
}

.benefit-4 p {
    font-size: 0.9rem;
}

.benefit-1, .benefit-2, .benefit-3, .benefit-4 {
    height: auto;
    width: auto;
    margin: 0.5rem;    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
} 

.bene-desc {
    display: none;
}

h6.bene-hover {
    color: #F04E31;
    text-align: center;
}

p.hover-desc {
    font-size: 0.7rem;
    text-align: center;
}

.benefit-1:hover .bene-desc{
    display: block;
}

.benefit-1:hover .bene-visible{
    display: none;
}

.benefit-2:hover .bene-desc{
    display: block;
}

.benefit-2:hover .bene-visible{
    display: none;
}

.benefit-3:hover .bene-desc{
    display: block;
}

.benefit-3:hover .bene-visible{
    display: none;
}

.benefit-4:hover .bene-desc{
    display: block;
}

.benefit-4:hover .bene-visible{
    display: none;
}

.benefit-1, .benefit-2, .benefit-3, .benefit-4{
    height: 310px;
}


@media only screen and (max-width:800px){
    .benefits{
        padding: 3rem 1rem;        
        height: auto;
		background-position: -175px;
		background-repeat: no-repeat;
		background-color:#1c1c1c;
    }
    .benefits h1{
        font-size: 42px;
    }
    .benefits p{
        font-size: 16px;
        max-width: 100%;
		padding: 0;
    }
    .benefit-1, .benefit-2, .benefit-3, .benefit-4{
        flex: 0 0 45%;
    }
}

@media only screen and (max-width:600px){
    .benefits{
        height: auto;
		background-position: -335px -90px;
		background-repeat: no-repeat;
		background-color:#1c1c1c;
    }
    .benefits h1{
        font-size: 24px;
        margin-bottom: 1rem;
    }
    .benefits p{
        font-size: 12px;
    }
    .benefit-wrapper{
        flex-direction: column;
        justify-content: center;
    }
    .benefit-wrapper img {
		width: 100%;
	}
}

@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .benefits{
        height: auto;
    }
    .benefit-1, .benefit-2, .benefit-3, .benefit-4{
        margin: 0.2rem;
    }
    .benefit-wrapper{
        justify-content: center;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .benefits{
        height: auto;
    } 
}