.ecomus {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/ecombg_One5Digital.png');
    width: 100%;
	background-position: -180px 0;
	background-repeat: no-repeat;
}

.ecomus h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.ecomus h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #fff;
}

.ecomus p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 50%;
}

.ecomus-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.ecomus-1 p {
    font-size: 0.9rem;
}

.ecomus-2 p {
    font-size: 0.9rem;
}

.ecomus-3 p {
    font-size: 0.9rem;
}

.ecomus-4 p {
    font-size: 0.9rem;
}

.ecomus-1, .ecomus-2, .ecomus-3, .ecomus-4 {
    height: 260px;
    width: 260px;
    margin: 1rem;    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ecousproject h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 1.2;
    color: #fff;
}

.ecousproject h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.ecousproject p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

.ecous-wrapper{
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
}

img.ecousimg{
    width: 100%;
}

ul.ecousrow-items{
    display: flex;
}

.ecousproject{
    margin-top: 2rem;
}


.ecousrow-items li {
    width: 25%;
    display: block;
}
ul.ecousrow-items {
	display: flex;
	flex-wrap: wrap;
}

@media only screen and (max-width:800px){
	.ecomus {
		background-size: 180%;
		background-position: -175px 0;
		background-color: #1c1c1c;
	}
    .ecomus{
        padding: 3rem 1rem;
        height: auto;
    }
    .ecomus h1{
        font-size: 42px;
		margin-bottom: 1rem;
    }
    .ecomus h6{
        font-size: 20px;
    }
    .ecomus p{
        font-size: 16px;
        width: 100%;
    }
    .ecomus-1, .ecomus-2, .ecomus-3, .ecomus-4 {
        height: 200px;
    }
    img.ecousimg {
        width: 100%;
    }
	.ecousrow-items li {
		width: 50%;
		display: block;
		margin-bottom: 10px;
		padding: 0 10px;
	}
	.ecomus-wrapper {
		margin-top: 1rem;
	}
}

@media only screen and (max-width:600px){
    .ecomus{
        height: auto;
    }
    .ecomus h1, .ecousproject h2{
        font-size: 24px;
    }
    .ecomus h6{
        font-size: 14px;
    }
    .ecomus p{
        font-size: 12px;
		padding: 0;
    }
    .ecomus-1, .ecomus-2, .ecomus-3, .ecomus-4 {
        height: 100px;
    }
    img.ecousimg {
        width: 100%;
    }
    .ecomus-wrapper{
        margin-top: 0;
    }
	.ecousrow-items li {
		width: 100%;
		padding: 0;
	}
	.ecomus {
		background-size: 370%;
		background-position: -145px 0;
		background-color: #1c1c1c;
	}
}