.aaexp {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
    width: 100%;
}

.aaexp h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    text-align: center;
}

.aaexp h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.aaexp p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
    width: 60%;
}

img.aui{
    width: 1200px;
}

img.auri {
    width: 500px;
}

.au-cat{
    display: flex;
    margin-top: 4rem;
}

.au-left{
    float: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center
}

.au-right{
    float: right;
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.au-video{
    height: auto;
    width: 100%;
}

@media only screen and (max-width:800px){
    .aaexp{
        padding: 3rem 1rem;
        height: auto;
    }
    .aaexp h1{
        font-size: 42px;
    }
    .aaexp h6{
        font-size: 16px;
    }
    .aaexp p{
        font-size: 16px;
        width: 100%;
		padding: 0 !important;
    }
    img.aui{
        width: 720px;
    }
    .au-cat{
        flex-direction: column;
    }
    .au-left{
        width: 100%;
    }
    .au-right{
        width: 100%;
        justify-content: center;
    }
	img.auri {
		width: 100%;
	}
   
}

@media only screen and (max-width:600px){
    .aaexp{
        height: auto;
    }
	
    .aaexp h1{
        font-size: 24px;
    }
    .aaexp p{
        font-size: 12px;
        width: 100%;
    }
    img.aui{
        width: 450px;
    }
 
}