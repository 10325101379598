.sdtestimonials {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/about-white_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sdtestimonials h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.2;
    color: #000;    
    text-transform: uppercase;
    text-align: center;
}

.sdtestimonials h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.sdtestimonials p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 0px;
    color: #000;
    text-align: center;
    width: 50%;
}

.sdtestimonial-btn{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.test{
    display: flex;
    margin-bottom: 2rem;
}
.test-span{
    height: 20px;
    width: 20px;
    margin: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.test-span::before{
    content: "";
    height: 10px;
    width: 10px;
    background-color: #d4d4d4;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}
.test-span:hover::before{
    background-color: #45454d;
}
.test-span[data-quote="${active}"]::before{
    background-color: #45454d;
}

@media only screen and (max-width:800px){
    .sdtestimonials{
        padding: 3rem 1rem;
        height: auto;
        background-size: 200%;
        background-position: 84%;
    }
    .sdtestimonials h1{
        font-size: 42px;
        width: 100%;
    }
    .sdtestimonials p{
        font-size: 14px;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .sdtestimonials{
        height: auto;
        background-size: cover;
        background-position: 55%;
    }
    /* .sdtestimonials h1{
        font-size: 24px;
        width: 100%;
    } */
    .sdtestimonials p{
        font-size: 12px;
        width:100%;
    }
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .testimonials{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .testimonials{
        height: auto;
    } 
}
