.softwaredev {
    padding: 8rem 5rem;
    height: auto;
    background-image: url('./assets/softwaredev_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    display: flex;
}

.softwaredev h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.softwaredev h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.softwaredev p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
}

.soft-left{
    float: left;
    width: 70%;
    margin-top: 5rem;
}

.soft-right{
    float: right;
    width: 30%;
    margin-top: -4rem;
}

@media only screen and (max-width:800px){
    .softwaredev{
        padding: 5rem 1rem;
        height: auto;
    }
    .softwaredev h1{
        font-size: 40px;
    }
    .softwaredev p{
        font-size: 16px;
    }
    .soft-vector{
        width: 140px;
    }
    .soft-right{
        margin-top: -2rem;
    }
    .soft-left{
        width: 100%;
    }
    .soft-right {
		display: none;
	}
}

@media only screen and (max-width:600px){
    .softwaredev{
        height: auto;
    }
    .softwaredev h1{
        font-size: 30px;
    }
    .softwaredev p{
        font-size: 14px;
    }
    .soft-vector{
        display: none;
    }
    .soft-left{
        margin-top: 0;
    }
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .softwaredev{
        height: auto;
    }
    .soft-left{
        margin-top: 0;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .softwaredev{
        height: auto;
    } 
}