.devprocess {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/about-black_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.devprocess h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.devprocess h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.devprocess p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
}

.dev-left{
    float: left;
    width: 50%;
}

.dev-right{
    float: right;
    width: 50%;
    align-content: flex-end;
}

li.dev-items { 
    color: #fff;
    font-size: 1rem;
}

.dev-list {
    overflow-y: scroll;
    white-space: nowrap;
    height: 350px;
}

.scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
  }
  
.scrollbar-warning::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}
  
.scrollbar-warning::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    background-color: #F5F5F5; 
}
  
.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F04E31; 
}

@media only screen and (max-width:800px){
    .devprocess{
        padding: 3rem 1rem;
        height: auto;
    }
    .devprocess h1{
        font-size: 26px;
    }
    .devprocess p{
        font-size: 12px;
        padding: 0;
    }
    .dev-left{
        width: 40%;
    }
    .dev-right{
        width: 60%;
    }
    .left-contact2 {
		width: 100%;
	}
	li.dev-items {
		margin-bottom: 30px;
	}
	.dev-list {
		overflow-y: unset;
		height: auto;
		white-space: normal;
	}
	.dev-items:last-child {
		margin: 0;
	}
}

@media only screen and (max-width:600px){
    .devprocess{
        height: auto;
		background-color: #1C1C1C;
		background-image: none;
    }
    .devprocess h1{
        font-size: 23px;
        width: 100%;
        margin-bottom: 10px;
    }
    .devprocess p{
        font-size: 12px;
    }
    .dev-left{
        width: 100%;
    }
    .dev-right{
        width: 100%;
        margin-top: 2rem;
    }   
    li.dev-items {
		padding: 0;
	}
}
@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .devprocess{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
      .devprocess{
        height: auto;
    } 
}