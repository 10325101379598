.expertize {
    padding: 5rem 5rem 5rem 0;
    height: auto;
    background-image: url('./assets/about-white_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    overflow:hidden;
}

.expertize h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
}

.expertize h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.expertize p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

.exp-left{
    float: left;
    width: 50%;
}

.exp-right{
    float: right;
    width: 40%;
    align-content: flex-end;
}

.exp-img {
    width: 100%;
    height: auto;
    margin-top: 0rem;
    display: block;
}

.exp-img-zoom{
    display: none;
}

li.exp-items {
    color: #000;
    list-style: disc;
    font-size: 1rem;
    padding-left: 5px;
}

::marker  {
    color: #F04E31;
}
.exp-list {
	padding-left: 20px;
}

@media only screen and (max-width:800px){
    .expertize{
        height: auto;
        padding: 3rem 1rem 3rem 0;
        display: flex;
		align-items: center;
    }

    .expertize h1{
        font-size: 42px;
        max-width: 90%;

    }
    .expertize p{
        font-size: 16px;
    }
    .exp-img{
        display: none;
    }
    .exp-img-zoom{
        display: block;
        height: auto;
		width: 100%;
		padding-right: 30px;
    }
    .exp-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .expertize{
        height: auto;
        padding: 3rem 1rem;
    }
    .expertize h1{
        font-size: 30px;
        max-width: 100%;
    }
    .exp-right p{
        font-size: 12px;
    }
    .exp-img-zoom{
        display: none;
    }
    .exp-left {
		display: none;
	}
    .exp-right{
        width: 100%;
    }
}