.about-us {
    padding: 5rem 5rem;
    height: auto;
    background-image: url('./assets/about-bg_One5Digital.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
	overflow: hidden;
}

.about-us h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
}

.about-us h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.about-us p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #bfbfbf;
    width: 80%;
}

.left-about{
    float: left;
    width: 80%;
}

.right-aboutp{
    float: right;
    width: 20%;
    margin-top: -7rem;
}

.right-desc p {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 0px;
}

.about-btn btn--outline:hover {
    background-color: #F04E31;
    color: #fff;
}

.about-us h1 span {
    margin: 0;
}

.avector{
    position: absolute;
    left: 73rem;
	z-index: 1;
}
img.avector{
    width: 240px;
    height: 240px;
    animation-name: rotate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
}
.right-aboutp .avector {
	left: auto;
}

@media only screen and (max-width:800px){
    .about-us{
        padding: 3rem 1rem;
        height: auto;
    }
    .about-us h1{
        font-size: 42px;
    }
    .about-us p{
        font-size: 16px;
        width: 380px;
    }
    .aimg{
        width: 180px;
    }
    .right-aboutp{
        width: 40%;
    }
    .desc{
        margin-top: 2rem;
    }
}

@media only screen and (max-width:600px){
    .about-us{
        height: auto;
    }
    .about-us h1{
        font-size: 30px;
		margin-bottom: 10px;
    }
    .about-us p{
        font-size: 12px;
    }
    .right-aboutp {
		width: 30%;
		display: none;
	}
	.about-us p {
		width: 100%;
	}
	.about-shortdesc {
		margin-bottom: 10px !important;
	}
	.about-us .left-about {
		float: left;
		width: 100%;
	}
	
}

@media only screen and (max-width:1280px) {
    /* For safari landscape: */
    .about-us{
        height: auto;
    }
    
}
@media only screen and (min-device-width : 801px) and (max-device-width : 1024px){
      .about-us{
        height: auto;
    } 
	.right-aboutp {
	width: 30%;
}
	
}