.crexp {
    padding: 5rem 5rem;
    height: auto;
    background:#1c1c1c;
    width: 100%;
}

.crexp h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    text-align: center;
}

.crexp h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

.crexp p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #fff;
    width: 60%;
}

img.cri{
    width: 1200px;
}

img.crri {
    width: 500px;
}

.cr-cat{
    display: flex;
    margin-top: 4rem;
}

.cr-left{
    float: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center
}

.cr-right{
    float: right;
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.cr-video{
    height: auto;
    width: 100%;
}

@media only screen and (max-width:800px){
    .crexp{
        padding: 3rem 1rem;
        height: auto;
    }
    .crexp h1{
        font-size: 42px;
    }
    .crexp h6{
        font-size: 16px;
    }
    .crexp p{
        font-size: 16px;
        width: 100%;
		padding: 0 !important;
    }
    img.cri{
        width: 720px;
    }
    .cr-cat{
        flex-direction: column;
    }
    .cr-left{
        width: 100%;
    }
    .cr-right{
        width: 100%;
        justify-content: center;
    }
	img.crri {
		width: 100%;
	}
   
}

@media only screen and (max-width:600px){
    .crexp{
        height: auto;
    }
	
    .crexp h1{
        font-size: 24px;
    }
    .crexp p{
        font-size: 12px;
        width: 100%;
    }
    img.cri{
        width: 450px;
    }
 
}