.sdpartner {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
} 

.sdpartner h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    text-align: center;
}

.sdpartner h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.sdpartner p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
}

.sdp-logos{
    margin-top: 3rem;
}

.sdplogo1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}
.sdplogo2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin-top: 4rem;
}
img.sdplogo {
    width: auto;
    display: block;
    padding: 0.5rem;
    height: 4rem;
    /* filter: grayscale(100%); */
}
/* img.sdlogo:hover{
    filter: grayscale(0%);
} */

@media only screen and (max-width:800px){
    .sdpartner{
        padding: 3rem 1rem;
        height: auto;
    }
    .sdp-logos{
        margin-top: 1rem;
    }
    .sdplogo1{
        flex-wrap: nowrap;
        margin-bottom: 1rem;
    }
    .sdplogo2{
        flex-wrap: nowrap;
    }
    img.sdplogo{
        padding: 0.5rem;
    }
}

@media only screen and (max-width:600px){
    .sdpartner{
        height: auto;
    }
    img.sdplogos2 {
        display: block;
        width: 445px;
    }
    .sdp-logos{
        margin-top: 1rem;
    }
}

@media only screen and (max-width:767px){
.sdplogo1, .sdplogo2 {
	flex-wrap: wrap;
	margin-bottom: 1rem;
}
img.sdplogo{
    height: auto;
}
.sdplogo2{
    margin-top: 0;
}
}