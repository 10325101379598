.seodetails {
    padding: 5rem;
    height: auto;
    background-color: #fff;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.seodetails h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
    margin-top: 3rem;
}

.seodetails h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.seodetails h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #000;
}

.seodetails p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
}

.seodetails-btn {
    margin-top: 2rem;
}

@media only screen and (max-width:800px){
    .seodetails{
        padding: 3rem 1rem;
        height: auto;
		overflow: hidden;
    }
    .seodetails h1{
        font-size: 42px;
    }
    .seodetails p{
        font-size: 16px;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .seodetails{
        padding: 3rem 1rem;
        height: auto;
    }
    .seodetails h1{
        font-size: 24px;
    }
    .seodetails p{
        font-size: 16px;
    }
	
}