.ldprice {
    padding: 5rem;
    height: auto;
    background: #fff;
}

.ldprice h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #000;
    margin-top: 3rem;
}

.ldprice h4 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: #000;
    text-align: center;
}

.ldprice p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000;
}

.ldbasic-btn {
    margin-top: 10rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ldstandred-btn{
    margin-top: 4rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ldpremium-btn{
    margin-top: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ldfp {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}


.ldf-col2 {
    background: #F04E31;
}

svg.check{
    color: #F04E31;
    margin-right: 1rem;
}

svg.checkw{
    color: #FFF;
    margin-right: 1rem;
}

h4.ldfw {
    color: #fff;
}

p.ldfw{
    color: #fff;
}

.ldf-col1, .ldf-col2, .ldf-col3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding: 2rem;
    height: auto;
    box-shadow: 1px 2px 5px 5px #eee;
}
.ldbasic-btn .btn.btn--orange, .ldstandred-btn .btn.btn--owhite, .ldpremium-btn  .btn.btn--orange {
	padding: 12px;
	display: block;
	width: 100%;
}
.ldbasic-btn a, .ldstandred-btn a, .ldpremium-btn a {
	display: block;
	width: 100%;
}
.ldfp li {
    width: 33.33%;
}
@media only screen and (max-width:800px){
    .ldprice{
        padding: 3rem 1rem;
        height: auto;
    }
    .ldprice h1{
        font-size: 34px;
        margin-top: 0;
    }
    .ldprice h4{
        font-size: 20px;
    }
    .ldprice p{
        font-size: 12px;
        width: 100%;
    }
    .ldf-col1, .ldf-col2, .ldf-col3{
        width: 100%;
        padding: 1rem;
        height: auto;
    }
    li.ldfli-col1, li.ldfli-col2, li.ldfli-col3{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .ldbasic-btn {
        margin-top: 8.5rem;
    }
    .ldstandred-btn {
        margin-top: 5.2rem;
    }
}

@media only screen and (max-width:600px){
    .ldprice{
        height: auto;
    }
    .ldprice p{
        font-size: 16px;
        width: 100%;
    }
    .ldfp{
        flex-direction: column;
    }
    .ldf-col1, .ldf-col2, .ldf-col3{
        width: 100%;
        padding: 2rem;
        height: auto;
    }
    .ldbasic-btn {
        margin-top: 2rem;
    }
    .ldstandred-btn {
        margin-top: 2rem;
    }
    .ldprice h1{
        font-size: 24px;
		margin-top: 0;
    }
	.ldfp li {
		width: 100%;
		padding: 0 !important;
		margin-bottom: 15px;
	}
}
@media only screen and (min-device-width : 801px) and (max-device-width : 1240px){
}