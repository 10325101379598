.wdservice {
    padding: 5rem 5rem;
    height: auto;
    background:#fff;
} 

.wdservice h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.wdservice h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.wdservice p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
}

.wdservice-wrapper {
    display: flex;
    flex: 1;
    margin-top: 3.5rem;
    flex-wrap: wrap;
}

.wdservice-1 p {
    font-size: 0.9rem;
}

.wdservice-2 p {
    font-size: 0.9rem;
}

.wdservice-3 p {
    font-size: 0.9rem;
}

.wdservice-4 p {
    font-size: 0.9rem;
}

ul.wdservice-1 {
    margin-left: 1rem;
    width: 24%;
    height: 175px;
}

ul.wdservice-2, ul.wdservice-3, ul.wdservice-4 {
    width: 24%;
    height: 175px;
}

p.wd-desc {
    color: #000;
    width: 90%;
    text-align: justify;
}

@media only screen and (max-width:800px){
    .wdservice{
        padding: 3rem 1rem;
        height: auto;
    }
    .wdservice h1{
        font-size: 42px;
    }
    .wdservice h6{
        font-size: 20px;
    }
    .wdservice p{
        font-size: 12px;
        width: 100%;
    }
    p.wd-desc{
        width: 80%;
    }
    .wdservice-wrapper{
        flex: 0 0 50%;
    }
    .wdservice-1, .wdservice-2, .wdservice-3, .wdservice-4{
        flex: 0 0 50%;
    }
    ul.wdservice-1{
        margin-left: 0;
    }
    ul.wdservice-1, ul.wdservice-2, ul.wdservice-3, ul.wdservice-4 {
        width: 24%;
        height: 175px;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width:600px){
    .wdservice{
        height: auto;
    }
    .wdservice p{
        font-size: 16px;
    }
    .wdservice-wrapper{
        flex-direction: column;
    }
    ul.wdservice-1, ul.wdservice-2, ul.wdservice-3, ul.wdservice-4 {
        width: 100%;
    }
    ul.wdservice-1{
        margin-left: 0;
    }
    .wdservice h1 {
		font-size: 24px;
	}
	p.wd-desc {
		width: 100%;
		padding: 0;
		text-align: left;
	}
	ul.wdservice-1, ul.wdservice-2, ul.wdservice-3, ul.wdservice-4 {
        height: auto;
    }
	ul.wdservice-4 {
        margin-bottom: 0;
    }
}