.sdabout {
    padding: 0 0 0 5rem;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

.sdabout-container {
    height: auto;
}

.sdaboutcol-left{
    float: left;
    color: #000;
    width: 70%;
}

.sdaboutcol-left h1 {
    margin: 5rem 0 2rem 0;
    font-size: 3rem;
    line-height: 1.2;
}

.sdaboutcol-full h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
}

.sdaboutcol-left p, .sdaboutcol-full p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    color: #000;
    width: 80%;
}

.sdaboutcol-left h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    color: #F04E31;
}

h3.year{
    margin-top: 0.5rem;
}

.sdaboutcol-left btn{
    margin-top: 1.2rem;
}

.sdaboutcol-right {
    float: right;
    width: 30%;
}

img.sdimg{
    width: 100%;
    margin-top: -50px;
    display: block;
}

img.sdimg2{
    display: none;
}

.ser-wrapper{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}
.ser-1 li {
    padding-left: 0;
}

.sbpro-slider{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

img.ssimg{
    height: 35rem;
    margin-top: 1rem;
    box-shadow: 2px 2px 2px 2px #d9d9d9;
}

.page-arrows, .pro {
    text-align: center;
}

/* Slideshow */

.sslideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 680px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .sslide {
    display: inline-block;
    height: 800px;
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #717171;
  }

@media only screen and (max-width:800px){
    .sdabout{
        padding: 3rem 1rem;
        height: auto;
        overflow: hidden;
    }
    .sdabout h1{
        font-size: 42px;
    }
    .sdabout h6{
        font-size: 16px;
    }
    .sdabout p{
        font-size: 16px;
        width: 100%;
    }
    img.sdimg{
        display: none;
    }
    img.sdimg2{
        display: block;
        margin-top: -50px;
    }    
    .sdaboutcol-left{
        width: 50%;
    }
    .sdaboutcol-right{
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .sdabout{
        height: auto;
    }
    .sdabout h1{
        font-size: 3rem;
        /* text-align: center; */
    }
    .sdabout p{
        font-size: 12px;
        width: 100%;
        padding: 0;
    }
    img.sdimg{
        width: 100%;
        margin-top: 0rem;
        display: block;
    }
    img.ssimg{
        width: auto;
        height: 30rem;
    }
    img.sdimg2{
        display: none;
    }
    .sdabout-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .sdaboutcol-full {
        width: 100%;
        order: 1;
    }
    .sdaboutcol-left{
        width: 100%;
        order: 3;
    }
    .sdaboutcol-right{
        width: 100%;
        order: 2;
        margin-bottom: 2rem;
    }
    .sdaboutcol-left h1{
        margin-top: 0;
    }
    
    
}