.VDesignabout {
    display: flex;
    justify-content: center;
    align-items: center;
}

.VDesignabout h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    color: #1c1c1c;
}

.VDesignabout h6 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #1c1c1c;
}

.VDesignabout p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 300;
    padding-right: 15px;
    color: #000000;
    width: 80%;
}

.VDesignabout-btn {
    margin-top: 2rem;
}

.designimg{
    height: 60vh;
    width: auto;
}

.left-side{
    float: left;
    width: 50%;
    padding: 5rem 0 5rem 5rem;
}

.right-side{
    float: right;
    width: 50%;
}

@media only screen and (max-width:800px){
    .VDesignabout{
        padding: 3rem 1rem 5rem;
        height: auto;
		overflow: hidden;
        flex-direction: column;
    }
    .VDesignabout h1{
        font-size: 42px;
    }
    .VDesignabout p{
        font-size: 16px;
        width: 80%;
        width: 100%;
		padding: 0;
    }
    .left-side{
        padding: 1rem 0 0 1rem;
        width: 100%;
    }
    .right-side{
        width: 100%;
        margin-top: 3rem;
    }
    .designimg{
        height: 40vh;
    }
}

@media only screen and (max-width:600px){
    .VDesignabout{
        padding: 3rem 1rem;
        height: auto;
        flex-direction: column;
    }
    .VDesignabout h1{
        font-size: 30px;
    }
    .VDesignabout p{
        font-size: 12px;
        width: 100%;
    }
    .left-side{
        width: 100%;
        padding: 0;
    }
    .right-side{
        width: 100%;
        margin-top: 3rem;
    }
    .designimg{
        width: 100%;
    }
}